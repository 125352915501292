import React, { useEffect, useState } from 'react'
import { VARIABLES } from '../../Constant';
import axios from 'axios'
import moment from 'moment'
import secureLocalStorage from 'react-secure-storage';

const PumpFeedback = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const userId = secureLocalStorage.getItem('userID')
  console.log(userId)
  const privileges = secureLocalStorage.getItem('privileges')
  //  All zone Feedback stored in "zoneLogs"
  const [pumpLogs, setPumpLogs] = useState([])
  console.log(pumpLogs)

  const onChangeSidebar = (section) => {
    props.handleSidebar(section)
  }

  // Get all Zone feedback by "apiZoneLogData" and stored in "zoneLogs"
  const apiPumpLogData = async () => {
    try {
      setIsLoading(true)
      const res = await axios.get(VARIABLES.url + '/api/current-entity-status-log?type=pump');
      setPumpLogs(res.data);
      setIsLoading(false)
    } catch (error) {
      console.log(error);
    }
  };

  // Get all Zone feedback by "apiZoneLogData" and stored in "zoneLogs"
  const apiPumpUserLogData = async () => {
    try {
      setIsLoading(true)
      const url = VARIABLES.url + '/api/current-entity-status-log?type=pump&user_id=' + userId
      console.log(url)
      const res = await axios.get(url);
      setPumpLogs(res.data);
      setIsLoading(false)
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect to load the API while page reload. 
  useEffect(() => {
    { privileges.includes("superadmin") && apiPumpLogData() }
    { privileges.includes("admin_plantowner") && apiPumpUserLogData() }
  }, [])

  return (
    <>
      <div className='nk-block-head' style={{paddingBottom:"1.25rem"}}>
        <div class="nk-block-head-content">
          <a href="javascript:void(0)" class="btn btn-outline-light bg-white d-none d-sm-inline-flex" onClick={()=> onChangeSidebar("dashboard")}><em
            class="icon ni ni-arrow-left"></em><span>Back</span></a>
          <a href="javascript:void(0)"
            class="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none" onClick={()=> onChangeSidebar("dashboard")}><em
              class="icon ni ni-arrow-left"></em></a>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-12 col-md-12 col-sm-12'>
          <div className='card'>
            <div class="card-inner">
              <div class="card-title-group">
                <div class="card-title">
                  <h6 class="page-title"><span class="me-2">Pump Switching Log</span></h6>
                </div>
              </div>
            </div>
            {isLoading ? <div className='card-inner'>Loading...</div> :
              <div className='card-inner'>
                <div className='table-responsive'>
                  <table className="table nk-tb-list nk-tb-orders">
                    <thead>
                      <tr class="nk-tb-item nk-tb-head">
                        <td className="nk-tb-col nk-tb-orders-type"><span>Sr.No</span></td>
                        <td className="nk-tb-col"><span>Pump Name</span></td>
                        <td className="nk-tb-col"><span>Pump Status</span></td>
                        <td className="nk-tb-col"><span>Pump Configurable Date</span></td>
                        <td className="nk-tb-col"><span>Pump Configurable Time</span></td>
                      </tr>
                    </thead>
                    {pumpLogs.length === 0 &&
                      <tbody>
                        <tr>
                          <td></td>
                          <td>No data found</td>
                          <td></td>
                        </tr>
                      </tbody>
                    }
                    {
                      pumpLogs.length > 0 &&
                      <tbody>
                        {pumpLogs.map((pump, index) => {
                          const config_date = moment(pump.config_last_updated).format('yyyy-MM-DD')
                          const config_time = moment(pump.config_last_updated).format('HH:mm')
                          return <tr>
                            <th className="nk-tb-col nk-tb-orders-type">
                              <span className="tb-lead">{index + 1}</span>
                            </th>
                            <td className="nk-tb-col">
                              <span className="tb-lead">{pump.entity_name}</span>
                            </td>
                            {pump.entity_status === "0" ? <td className='nk-tb-col text-danger'>OFF</td> : <td className='nk-tb-col text-sucess'>ON</td>}
                            <td className="nk-tb-col">
                              <span className="tb-sub text-primary">{config_date}</span>
                            </td>
                            <td className="nk-tb-col">
                              <span className="tb-sub text-primary">{config_time}</span>
                            </td>
                          </tr>
                        })}
                      </tbody>
                    }
                  </table>
                </div>
              </div>}
          </div>
        </div>
      </div>
    </>
  )
}

export default PumpFeedback