import axios from 'axios';
import React, { useEffect, useState } from 'react'
import secureLocalStorage from 'react-secure-storage';
import { VARIABLES } from '../../Constant';
import AllAdminStatusCard from './AllAdminStatusCard';

const AllAdminStatus = (props) => {
  const privileges = secureLocalStorage.getItem('privileges')
  // isLoading state to load data from api
  const [isLoading, setIsLoading] = useState(false)

  // Onchnage handle to swtich from one page to anathor
  const onChangeSidebar = (section) => {
    props.handleSidebar(section)
  }

  // all admin Details are store in "admins" state
  const [admins, setAdmins] = useState([])
  console.log(admins)

  // api call to get the admin details role wise and store in state "admins"
  const apiAdminDetails = async () => {
    try {
      setIsLoading(true)
      const res = await axios.get(VARIABLES.url + '/api/userdetails?role=admin');
      setAdmins(res.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    apiAdminDetails();
  }, [])

  return (
    <>
      {privileges.includes("back_dashboard") && <div className='nk-block-head' style={{paddingBottom:"1.25rem"}}>
        <div class="nk-block-head-content">
          <a href="javascript:void(0)" class="btn btn-outline-light bg-white d-none d-sm-inline-flex" onClick={()=> onChangeSidebar("dashboard")}><em
            class="icon ni ni-arrow-left"></em><span>Back</span></a>
          <a href="javascript:void(0)"
            class="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none" onClick={()=> onChangeSidebar("dashboard")}><em
              class="icon ni ni-arrow-left"></em></a>
        </div>
      </div>}

      {/* Heading */}
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title page-title admin_overview">Admin's Overview</h4>
          </div>
        </div>
      </div>

      {/* Admin All Cards are mapped here */}
      <AllAdminStatusCard isLoading={isLoading} admins={admins} handleSidebar={props.handleSidebar} />
    </>
  )
}

export default AllAdminStatus