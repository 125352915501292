import React from 'react'
import axios from 'axios';
import Swal from 'sweetalert2';
import { VARIABLES } from '../../Constant';
import secureLocalStorage from "react-secure-storage";
import { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment';
import DeleteModalTank from '../Modals/DeleteModalTank';

const TankRender = (props) => {
  const privileges = secureLocalStorage.getItem('privileges')
  const [deleteTankResponse,setDeleteTankResponse]=useState(null)
  console.log(deleteTankResponse)
  const [userId,setUserId]=useState(null)
  const [tankUpdate, setTankUpdate] = useState(null)

  const [lastTankFeedbackName, setLastTankFeebbackName] = useState()
  const [lastTankFeedbackTime, setLastTankFeebbackTime] = useState()
  console.log(lastTankFeedbackName)
  console.log(lastTankFeedbackTime)

  const onChangeSidebar = (section, tank) => {
    props.handleSidebar(section)
    props.setTankHandleDetails(tank)
  }
  // Delete Function : delete the specific id 
  const deleteTank = () => {
    var config = {
      method: 'delete',
      url: VARIABLES.url + `/api/delete?id=${userId}&type=entity`,
      headers: {}
    };
    console.log("deleted project reached");
    axios(config).then((response) => {
      Swal.fire({
        text: 'Tank Deleted Successfully!',
        icon: 'success'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          console.log("yes")
          document.getElementById('closeModalTank').click();
        }
      })
      console.log(JSON.stringify(response.data));
      props.setTankDetails(props.tankDetails.filter((user) => {
        return user.id !== userId
      }));
    })
      .catch((error) => {
        Swal.fire({
          text: 'Failed to Delete Tank',
          icon: 'warning'
        })
        console.log(error);
      });
  };

  const deleteTankAlert = async(id) => {
    try {
      const res = await axios.get(VARIABLES.url+"/api/delete-confirmation?id="+id+"&type=entity");
      document.getElementById('tankmodalDelete').click();
      console.log(res.data)
      setDeleteTankResponse(res.data)
      setUserId(id)
    } catch (error) {
      console.log(error);
    }

  };

  const apiLastTankFeedback = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/current-entity-status?entity_id=' + props.tank.id);
      if (res.data.length === 0) {
        setLastTankFeebbackName("No Logs");
        setLastTankFeebbackTime("No Logs");
        setTankUpdate(false)
      } else if (res.data[0].entity_status == "0") {
        setLastTankFeebbackName(res.data[0].entity.name);
        setLastTankFeebbackTime(moment(res.data[0].config_last_updated).format('hh:mm a'));
        setTankUpdate(false)
      } else {
        setLastTankFeebbackName(res.data[0].entity.name);
        setLastTankFeebbackTime(moment(res.data[0].config_last_updated).format('hh:mm a'));
        setTankUpdate(true)
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Set the interval for the API call (e.g., every 1 minute)
  const intervalTime = 60000; // 1 min in milliseconds

  const apiLastTankLogs = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/current-entity-status?entity_id=' + props.tank.id);
      console.log(res.data)
      if (res.data.length === 0) {
        setLastTankFeebbackName("No Logs");
        setLastTankFeebbackTime("No Logs");
        setTankUpdate(false)
      } else if (res.data[0].entity_status === "0") {
        setLastTankFeebbackName(res.data[0].entity.name);
        setLastTankFeebbackTime(moment(res.data[0].config_last_updated).format('hh:mm a'));
        setTankUpdate(false)
      } else {
        setLastTankFeebbackName(res.data[0].entity.name);
        setLastTankFeebbackTime(moment(res.data[0].config_last_updated).format('hh:mm a'));
        setTankUpdate(true)
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   const intervalId = setInterval(apiLastTankFeedback, intervalTime);

  //   // To stop the interval when the component unmounts
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [props.tank]);

  useEffect(() => {
    apiLastTankLogs();
  }, [props.tank])

  return (
    <>

      {tankUpdate !== null && <div className="col-lg-8 col-md-10 col-sm-12" style={{ marginTop: "20px" }}>
        <div className="card h-100">
          <div className={`d-flex justify-content-between align-items-start mt-3 ${tankUpdate === false ? "field_off" : "field_on"}`}>
            <a href="javascript:void(0)" className="ms-4 d-flex align-items-center">
              <div className="my-1">
                <h5 className="title">{props.tank.name}</h5>
              </div>
            </a>

            {privileges.includes("tank_ellipsis") && <div className="dropdown me-3 my-1">
              <a href="#" className="dropdown-toggle btn btn-sm btn-icon btn-trigger me-n1" data-bs-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
              <div className="dropdown-menu dropdown-menu-end">
                <ul className="link-list-opt no-bdr">
                  {privileges.includes("edit_tank") && <li><a href='javascript:void(0)' data-bs-toggle="modal" data-bs-target="#editTank" onClick={() => props.editTankDetails(props.tank.id)}><em className="icon ni ni-edit"></em><span>Edit Tank</span></a></li>}
                  {privileges.includes("delete_tank") && <li><a href='javascript:void(0)' onClick={() => deleteTankAlert(props.tank.id)}><em className="icon ni ni-delete"></em><span>Delete Tank</span></a></li>}
                </ul>
              </div>
            </div>}
          </div>
          <div className="card-inner">
          {privileges.includes("tank_setting") && <div className="team-view-button">
              <a href="javascript:void(0)" className="btn btn-outline-light w-100px" onClick={() => onChangeSidebar("TankCardHandler", props.tank)}><span>Setting</span></a>
              {/* <div className=''>
                <span className="sub-text-mysync">Last <span className='fw-bold'>{lastTankFeedbackName} <br />{lastTankFeedbackTime}</span></span>
              </div> */}
            </div>}
            <div class="invest-data mt-2">
              <div class="invest-data-amount g-2">
                <div class="invest-data-history">
                  <div class="title">Current Status</div>
                  {lastTankFeedbackName ? <div class="amount">{lastTankFeedbackName} </div> : <p>Loading...</p>}
                  {lastTankFeedbackTime ? <div class="amount">{lastTankFeedbackTime} </div> : <p>Loading...</p>}
                </div>
              </div>
            </div>
            {/* <div className='mt-2'>
              <span className="sub-text-sync">Last Update on : {updatedTime}</span>
            </div> */}
            {/* <div className='d-flex justify-content-between'>
              <span className="sub-text-sync">Refresh the button</span>
              <a href="javascript:void(0)" className="logo-link" onClick={() => syncRefresh(props.tank.id)}>
                <img className="img-fluid" src="./images/sync.png" srcSet="./images/sync.png" alt="sync" />
              </a>
            </div> */}
          </div>
        </div>
      </div>}

      <DeleteModalTank message="Warning: Deleting this Tank will effects the below following entities:"
      deleteResponse={deleteTankResponse} 
      deleteEntity={deleteTank}
      userId={userId} />
      <button
        id="tankmodalDelete"
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#assignedDeleteTank"
        style={{ display: 'none' }}
      >
        Open Modal 2
      </button>
      <button
        id="closeModalTank"
        type="button"
        className="btn btn-primary"
        data-bs-target="#assignedDeleteTank"
        data-bs-dismiss="modal" aria-label="Close"
        style={{ display: 'none' }}
      >
        Close Modal 2
      </button>

    </>
  )
}

export default TankRender