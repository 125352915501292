import React, { useState,useContext,useEffect } from 'react'
import Content from './Content'
import Header from './Header'
import Sidebar from './Sidebar'
import  secureLocalStorage  from  "react-secure-storage";
import { PumpDetailsContext } from '../../Context/PumpDetailsContext';


const Base = (props) => {
  const plantId = secureLocalStorage.getItem('plant_id')
  console.log(plantId)
  const {
    sidebarPumps,
    setPumpDetails,
    isPumpActive,
    apiPumpDetails,
    apiPumpPlantOwnerDetails,
    setIsPumpActive} = useContext(PumpDetailsContext);

  // to get the set Privileges from local storage
  const privileges = secureLocalStorage.getItem('privileges')

  // Selected Sidebar stored in "sidebar" state 
  // privileges[0] means the "the zero index privillegs firstly sets here in sidebar"
  const [sidebar,setSidebar] = useState(privileges[0]);
  console.log(sidebar)

  // OnChnage handler to get the sidebar name 
  const handleSidebar =(section) => {
    setSidebar(section)
  }

  // "showSidebar" state handles the sidebar in mobile responsive to toggle the hide and show case
  const [showSidebar ,setShowSidebar] = useState(false)
  const showSidebarMobile = () => {
    setShowSidebar(true)
  }
  const hideSidebar = () => {
    setShowSidebar(false)
  }

  // useEffect(() => {
  //   {privileges.includes("plantowner") && apiPumpPlantOwnerDetails()} // plant owner
  //   {privileges.includes("superadmin_admin") && plantId && apiPumpDetails() }  //admin and super admin
  // },[plantId])

  return (
    <>
      <div className="nk-app-root">
        {/* <!-- main @s --> */}
        <div className="nk-main ">
          {/* <!-- sidebar @s --> */}
           <Sidebar
           showSidebar={showSidebar} 
           hideSidebar={hideSidebar} 
           handleSidebar={handleSidebar}
           setSidebar={setSidebar} />
          {/* <!-- sidebar @e --> */}
          {/* <!-- wrap @s --> */}
          <div className="nk-wrap ">
            {/* <!-- main header @s --> */}
            <Header showSidebarMobile={showSidebarMobile} logoutHandler={props.logoutHandler} />
            {/* <!-- main header @e --> */}
            {/* <!-- content @s --> */}
            <Content
            sidebar={sidebar} 
            handleSidebar={handleSidebar} />
            {/* <!-- content @e --> */}
            {/* <!-- footer @s --> */}
            {/* <!-- footer @e --> */}
          </div>
          {/* <!-- wrap @e --> */}
        </div>
        {/* <!-- main @e --> */}
      </div>
      {/* <!-- app-root @e --> */}
    </>
  )
}

export default Base