import React, { useEffect, useState } from 'react'
import ReactSwitch from 'react-switch';
import { Controller, useForm } from "react-hook-form";
import axios from 'axios';
import { VARIABLES } from '../../Constant';
import Swal from 'sweetalert2';
import secureLocalStorage from "react-secure-storage";
import PumpCreateScheduler from './PumpCreateScheduler';
import PumpEditScheduler from './PumpEditScheduler';
import PumpSchedulerTable from './PumpSchedulerTable';

const PumpScheduler = (props) => {
  const privileges = secureLocalStorage.getItem('privileges')

  const onChangeSidebar = (section) => {
    props.handleSidebar(section)
  }

  const [isLoading, setIsLoading] = useState(false)

  const [checked, setChecked] = useState(false);
  console.log(checked)

  const [schedulerData, setSchedularData] = useState([])
  console.log(schedulerData);

  const [weekDays, setWeekDays] = useState([])

  // tO ASSIGNED entity value
  const [pumps, setPumps] = useState([{value:props.schedularZone.id,label: props.schedularZone.name}])

  const [editedScheduler, setEditedScheduler] = useState(null)

  const apiSchedulerData = async () => {
    try {
      setIsLoading(true)
      const res = await axios.get(VARIABLES.url + `/api/scheduler-details?entity_id=${props.schedularZone.id}`);
      setSchedularData(res.data)
      setIsLoading(false)
      console.log(res.data)
    } catch (error) {
      console.log(error);
    }
  };

  const apiWeekDaysData = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/category-master?type=days');
      setWeekDays(res.data.map((day) => ({
        value: day.name,
        label: day.name
      })))
      console.log(res.data)
    } catch (error) {
      console.log(error);
    }
  };

  const editSchedule = (id) => {
    const getEditedScheduler = schedulerData.find((data) => {
      return data.id === id
    })
    console.log(getEditedScheduler);
    setEditedScheduler(getEditedScheduler);
  }

  useEffect(() => {
    apiSchedulerData();
    apiWeekDaysData();
  }, [])

  return (
    <>
      {privileges.includes("back_pump") && <div className='nk-block-head' style={{paddingBottom:"1.25rem"}}>
        <div class="nk-block-head-content">
          <a href="javascript:void(0)" class="btn btn-outline-light bg-white d-none d-sm-inline-flex" onClick={()=> onChangeSidebar("pump_details")}><em
            class="icon ni ni-arrow-left"></em><span>Back</span></a>
          <a href="javascript:void(0)"
            class="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none" onClick={()=> onChangeSidebar("pump_details")}><em
              class="icon ni ni-arrow-left"></em></a>
        </div>
      </div>}

      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title page-title">{props.schedularZone.name}</h4>
          </div>
          <div class="nk-block-head-content">
            <div className="col-auto d-flex w-sm-100">
              {privileges.includes("particular_pump_logs") && <button type="button" className="btn btn-sm btn-primary me-3" onClick={() => onChangeSidebar("particular pump feedback")}><span>Pump Switching Log</span></button>}
              {privileges.includes("add_pump_scheduler") && <button type="button" className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#setSchedule"><em class="icon ni ni-plus"></em><span>Scheduler Timer</span></button>}
            </div>
          </div>
        </div>
      </div>

      <PumpSchedulerTable
        schedularZone={props.schedularZone}
        isLoading={isLoading}
        schedulerData={schedulerData}
        setSchedularData={setSchedularData}
        editSchedule={editSchedule} />

      <PumpCreateScheduler
        schedularZone={props.schedularZone}
        apiSchedulerData={apiSchedulerData}
        pumps={pumps}
        weekDays={weekDays} />

      <PumpEditScheduler
        editedScheduler={editedScheduler}
        apiSchedulerData={apiSchedulerData}
        pumps={pumps}
        weekDays={weekDays} />
    </>
  )
}

export default PumpScheduler