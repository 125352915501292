import React, { useEffect } from 'react'
import moment from 'moment'

const ParticularTankCardFeedback = (props) => {
  
  return (
    <>
      <div className='row'>
        <div className='col-lg-12 col-md-12 col-sm-12'>
          <div className='card'>
          <div class="card-inner">
              <div class="card-title-group">
                <div class="card-title">
                  <h6 class="title"><span class="me-2">Water Tank Logged Notification</span></h6>
                </div>
              </div>
            </div>
            <div className='card-inner'>
              <div className='table-responsive'>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Sr. No</th>
                      <th scope="col">Tank Name</th>
                      <th scope="col">Tank Status</th>
                      <th scope="col">Tank Configurable Date</th>
                      <th scope="col">Tank Configurable Time</th>
                    </tr>
                  </thead>
                  {props.particulartankLogs.length === 0 &&
                    <tbody>
                      <tr>
                        <td></td>
                        <td>No data found</td>
                        <td></td>
                      </tr>
                    </tbody>
                  }
                  {
                    props.particulartankLogs.length > 0 &&
                    <tbody>
                      {props.particulartankLogs.map((tank, index) => {
                        const congif_date = moment(tank.config_last_updated).format('yyyy-MM-DD')
                        const congif_time = moment(tank.config_last_updated).format('HH:mm')
                        return <tr>
                          <th scope="row"> {index + 1} </th>
                          <td> {tank.entity_name} </td>
                          {tank.entity_status === "0" ? <td className='text-danger'>Tank is Empty</td> : <td className='text-success'>Tank is Filled</td>}  
                          <td> {congif_date} </td>
                          <td> {congif_time} </td>
                        </tr>
                      })}
                    </tbody>
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ParticularTankCardFeedback