import React, { useContext, useEffect, useState } from 'react'
import TankStatusCreate from './TankStatusCreate'
import TankStatusEdit from './TankStatusEdit'
import TankStatusTable from './TankStatusTable'
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from '../../Constant';
import axios from 'axios';
import { PumpDetailsContext } from '../../Context/PumpDetailsContext';

const TankStatus = (props) => {
  // Loading State.
  const [isLoading,setIsLoading] = useState(false)

  const {
    setPumpDetails,pumpDetails} = useContext(PumpDetailsContext);

  // Stored Secure Local storage data.
  const privileges = secureLocalStorage.getItem('privileges')
  const pumpId = secureLocalStorage.getItem('pump_id')
  const plantId = secureLocalStorage.getItem('plant_id')
  const userId = secureLocalStorage.getItem('userID')
  
  const [tankDetails,setTankDetails] = useState([])
  console.log(tankDetails)

  // Stored user "name" and "id" in label and value format from "apiUserDetails"
  const [userdetails,setUserDetails] = useState([]);


  const [pumpDetailsforTank,setPumpDetailsforTank] = useState([])
  console.log(pumpDetailsforTank)

  // Stored Node Id in "nodeData" state.
  const [nodeData,setNodeData] = useState([])

  // Stored Pin number in "pinNumber" state.
  const [pinNumber,setPinNumber] = useState([])

  // Edited particular Tank details stored in "editedTankDetails"
  const [editedTankDetails,setEditedTankDetails] = useState(null)

  // Onchange sidebar handler to navigate back
  const onChangeSidebar = (section) => {
    props.handleSidebar(section)
  }

  // API user details to list plant owner in dropdown of "User"
  const apiUserDetails = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/userdetails?role=plant owner');
      setUserDetails(
        res.data.map((userData) => ({
          value: userData.id,
          label: userData.user.username
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  // provisin for plant owner to get tha tank details
  const getTankDetailsforPlantOWner = async () => {
    try {
      setIsLoading(true)
      const res = await axios.get(VARIABLES.url+"/api/entity-details?type=tank&parent_id=" +pumpDetails);
      setTankDetails(res.data);
      setIsLoading(false)
    } catch (error) {
      console.log(error);
    }
  };

  // provison for plant owner
  const apiTankPlantDropdownDetails = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/entity-details?type=pump&parent_id='+plantId);
      setPumpDetailsforTank(
        res.data.map((userData) => ({
          value: userData.id,
          label: userData.name
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  // provision for plantowner to populate pump dropdown in tank form  
  const apiPumptoTankDetails = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/entity-details?type=pump&user_id=' + userId);
      setPumpDetailsforTank(
        res.data.map((userData) => ({
          value: userData.id,
          label: userData.name
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const apiNodeData = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/category-master?type=node');
      setNodeData(res.data.map((node) => ({
        value: node.name,
        label: node.name
      })))
      console.log(res.data)
    } catch (error) {
      console.log(error);
    }
  };

  const apiPinNumber = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/category-master?type=input_pins');
      setPinNumber(res.data.map((pin) => ({
        value: pin.name,
        label: pin.name
      })))
      console.log(res.data)
    } catch (error) {
      console.log(error);
    }
  }

  const editTankDetails = (id) => {
    const getTankDetails = tankDetails.find((tank) => {
      return tank.id === id
    })
    console.log(getTankDetails);
    setEditedTankDetails(getTankDetails);
  }

  useEffect(() => {
    apiUserDetails();
    apiNodeData();
    apiPinNumber();
    {privileges.includes("superadmin_admin") && apiTankPlantDropdownDetails()}
    getTankDetailsforPlantOWner()
    {privileges.includes("plantowner") && apiPumptoTankDetails()}
  },[])
  return (
    <>
      {privileges.includes("view tank") && <div className="arrow_div">
      <div className="nk-block-between">
        <div class="nk-block-head-content">
          <a href='javascript:void(0)' onClick={()=> onChangeSidebar("PlantDetails")}><em class="icon_size icon ni ni-chevron-left"></em></a>
        </div>
        {/* <div class="nk-block-head-content">
          <a href='javascript:void(0)'><em class="icon_size icon ni ni-chevron-right"></em></a>
        </div> */}
        </div>
      </div>}

      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Water Tank Management</h3>
          </div>
          {/* <!-- .nk-block-head-content --> */}
          <div className="nk-block-head-content">
            <div className="col-auto d-flex w-sm-100">
              <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#createTank"><em class="icon ni ni-plus"></em><span>Add Water Tank</span></button>
            </div>
          </div>
          {/* <!-- .nk-block-head-content --> */}
        </div>
        {/* <!-- .nk-block-between --> */}
      </div>
      <div className='divider'></div>

      <TankStatusCreate 
      userdetails={userdetails}
      getTankDetailsforPlantOWner={getTankDetailsforPlantOWner}
      pumpDetailsforTank={pumpDetailsforTank}
      nodeData={nodeData}
      pinNumber={pinNumber} />

      <TankStatusEdit 
      userdetails={userdetails}
      getTankDetailsforPlantOWner={getTankDetailsforPlantOWner}
      editedTankDetails={editedTankDetails}
      pumpDetailsforTank={pumpDetailsforTank}
      nodeData={nodeData}
      pinNumber={pinNumber} />

      <TankStatusTable 
      editTankDetails={editTankDetails}
      isLoading={isLoading} 
      tankDetails={tankDetails}
      setTankDetails={setTankDetails}
      handleSidebar={props.handleSidebar}
      setTankHandleDetails={props.setTankHandleDetails} />
    </>
  )
}

export default TankStatus