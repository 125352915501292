import React, { useEffect, useState } from 'react'
import { VARIABLES } from '../../Constant';
import axios from 'axios'
import moment from 'moment'
import secureLocalStorage from "react-secure-storage";


const ParticularPumpFeedback = (props) => {
  const privileges = secureLocalStorage.getItem('privileges')

  const [particularPumpLogs, setParticularPumpLogs] = useState([])
  const apiParticularPumpLogData = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/current-entity-status-log?entity_id=' + props.schedularZone.id);
      setParticularPumpLogs(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeSidebar = (section) => {
    props.handleSidebar(section)
  }

  useEffect(() => {
    apiParticularPumpLogData();
  }, [])
  return (
    <>
      {privileges.includes("back_scheduler") && <div className='nk-block-head' style={{paddingBottom:"1.25rem"}}>
        <div class="nk-block-head-content">
          <a href="javascript:void(0)" class="btn btn-outline-light bg-white d-none d-sm-inline-flex" onClick={()=> onChangeSidebar("PumpSchedular")}><em
            class="icon ni ni-arrow-left"></em><span>Back</span></a>
          <a href="javascript:void(0)"
            class="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none" onClick={()=> onChangeSidebar("PumpSchedular")}><em
              class="icon ni ni-arrow-left"></em></a>
        </div>
      </div>}
      <div className='row mx-0'>
        <div className='col-lg-12 col-md-12 col-sm-12'>
          <div className='card'>
            <div class="card-inner">
              <div class="card-title-group">
                <div class="card-title">
                  <h6 class="title"><span class="me-2">Pump Switching Log</span></h6>
                </div>
              </div>
            </div>
            <div className='card-inner'>
              <div className='table-responsive'>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Sr. No</th>
                      <th scope="col">Pump Name</th>
                      <th scope="col">Pump Status</th>
                      <th scope="col">Pump Configurable Date</th>
                      <th scope="col">Pump Configurable Time</th>
                    </tr>
                  </thead>
                  {particularPumpLogs.length === 0 &&
                    <tbody>
                      <tr>
                        <td></td>
                        <td>No data found</td>
                        <td></td>
                      </tr>
                    </tbody>
                  }
                  {
                    particularPumpLogs.length > 0 &&
                    <tbody>
                      {particularPumpLogs.map((pump, index) => {
                        const congif_date = moment(pump.config_last_updated).format('yyyy-MM-DD')
                        const congif_time = moment(pump.config_last_updated).format('HH:mm')
                        return <tr>
                          <th scope="row"> {index + 1} </th>
                          <td> {pump.entity_name} </td>
                          {pump.entity_status === "0" ? <td className='text-danger'>OFF</td> : <td className='text-sucess'>ON</td>}
                          <td> {congif_date} </td>
                          <td> {congif_time} </td>
                        </tr>
                      })}
                    </tbody>
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ParticularPumpFeedback