export const VARIABLES = {
    // url : "http://178.128.196.165:8010",
    // url :"http://192.168.1.5:8000",
    // url : "https://zs-api-test.techwalnut.co.in", 
    url : "https://demozs-api.elorca.com",
    subdomain : "zoomsolar",
    project_id : "3ABE4F1A",
    node_id : "9D91C697",
    ca : "/zs-dashboard/certs/fullchain.pem",
    cert : "/zs-dashboard/certs/fullchain.pem",
    key : "/zs-dashboard/certs/privkey.pem"
}; 