import React from 'react'

const Heading = (props) => {
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">{props.heading}</h3>
            <div className="nk-block-des text-soft subheading-text">
              <p>{props.subheading}</p></div>
          </div>
          {/* <!-- .nk-block-head-content --> */}
        </div>
      </div>
    </>
  )
}

export default Heading