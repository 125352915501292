import React from 'react'
import axios from 'axios';
import Swal from 'sweetalert2';
import { VARIABLES } from '../../Constant';

const TankStatusTable = (props) => {
  const onChangeSidebar = (section, tank) => {
    props.handleSidebar(section)
    props.setTankHandleDetails(tank)
  }
  // Delete Function : delete the specific id 
  const deleteTank = (id) => {
    var config = {
      method: 'delete',
      url: VARIABLES.url + `/api/entity-details/${id}`,
      headers: {}
    };
    console.log("deleted project reached");
    axios(config).then((response) => {
      Swal.fire({
        text : 'Tank Deleted Successfully!',
        icon : 'success'
      })
      console.log(JSON.stringify(response.data));
      props.setTankDetails(props.tankDetails.filter((user) => {
        return user.id !== id
      }));
    })
      .catch((error) => {
        Swal.fire({
          text : 'Failed to Delete Tank',
          icon : 'warning'
        })
        console.log(error);
      });
  };

  const deleteTankAlert = (id) => {
    Swal.fire({
      title: 'Are you sure? You will not be able to recover this Tank!',
      showDenyButton: true,
      confirmButtonText: 'Yes, delete it!',
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteTank(id);
      }
    })

  };

  return (
    <>
      {/* <div className='row mx-0'>
        <div className='col-lg-12 col-md-12 col-sm-12'>
          <div className='card'>
            <div class="card-inner">
              <div class="card-title-group">
                <div class="card-title">
                  <h6 class="title"><span class="me-2">Water Tank Management</span></h6>
                </div>
              </div>
            </div> */}
      {props.isLoading ? <div className='card-inner'>Loading...</div> :
        <div className='row'>
          {props.tankDetails.length === 0 &&
            <div className='row'>
              <div className='col-sm-12' style={{ marginTop: "20px" }}>
                <div className='card'>
                  <div className='card-inner'>
                    Tanks are not created
                  </div>
                </div>
              </div>
            </div>
          }
          {props.tankDetails.map((tank) => {
            return <div className="col-lg-4 col-md-12 col-sm-12" style={{ marginTop: "20px" }}>
              <div className="card h-100">
                <div className="card-inner">
                  <div className="d-flex justify-content-between align-items-start mb-3">
                    <a href="javascript:void(0)" className="d-flex align-items-center">
                      <div className="ms-3">
                        <h5 className="title mb-1">{tank.name}</h5>
                        <span className="sub-text">Pin Number : {tank.pin_number} </span>
                        <span className="sub-text">Node ID : {tank.node_id} </span>
                      </div>
                    </a>
                    <div className="dropdown">
                      <a href="#" className="dropdown-toggle btn btn-sm btn-icon btn-trigger mt-n1 me-n1" data-bs-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                      <div className="dropdown-menu dropdown-menu-end">
                        <ul className="link-list-opt no-bdr">
                          <li><a href='javascript:void(0)' data-bs-toggle="modal" data-bs-target="#editTank" onClick={() => props.editTankDetails(tank.id)}><em className="icon ni ni-edit"></em><span>Edit Tank</span></a></li>
                          <li><a href='javascript:void(0)' onClick={() => deleteTankAlert(tank.id)}><em className="icon ni ni-delete"></em><span>Delete Tank</span></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="team-view">
                    <a href="javascript:void(0)" className="btn btn-round btn-outline-light w-150px" onClick={() => onChangeSidebar("TankCardHandler", tank)}><span>View</span></a>
                  </div>
                </div>
              </div>
            </div>
          })
          }
        </div>
        // <div className='card-inner'>
        //   <div className='table-responsive'>
        //     <table className="table">
        //       <thead>
        //         <tr>
        //           <th scope="col">Sr. No</th>
        //           <th scope="col">Id</th>
        //           <th scope="col">Tank Name</th>
        //           <th scope="col">Edit</th>
        //           <th scope="col">Delete</th>
        //         </tr>
        //       </thead>
        //       {props.tankDetails.length === 0 &&
        //           <tbody>
        //             <tr>
        //               <td></td>
        //               <td>No data found</td>
        //               <td></td>
        //             </tr>
        //           </tbody>
        //         }
        //         {
        //           props.tankDetails.length > 0 &&
        //           <tbody>
        //             {props.tankDetails.map((tank, index) => {
        //               return <tr>
        //               <th scope="row"> {index + 1} </th>
        //               <td> {tank.id} </td>
        //               <td> {tank.name} </td>
        //               <td> <button className='ni ni-edit btn btn-outline-primary' data-bs-toggle="modal" data-bs-target="#editTank" onClick={() => props.editTankDetails(tank.id)}></button> </td>
        //               <td> <button className='ni ni-trash btn btn-outline-primary' onClick={() => deleteTankAlert(tank.id)}></button> </td>
        //             </tr>
        //             })}
        //           </tbody>
        //         }
        //     </table>
        //   </div>
        // </div>
      }
      {/* </div>
        </div>
      </div> */}
    </>
  )
}

export default TankStatusTable