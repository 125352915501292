import axios from 'axios';
import React, { useEffect,useContext } from 'react'
import { Controller, useForm,useFormState } from "react-hook-form";
import Select from 'react-select'
import Swal from 'sweetalert2';
import { VARIABLES } from '../../Constant';
import  secureLocalStorage  from  "react-secure-storage";
import swal from 'sweetalert';
import { PumpDetailsContext } from '../../Context/PumpDetailsContext';

const PumpManagementEdit = (props) => {
  // local storage values
  const privileges = secureLocalStorage.getItem('privileges')
  const LoggedId = secureLocalStorage.getItem("loggedId")

  const {apiPumpDetails,
    apiPumpPlantOwnerDetails} = useContext(PumpDetailsContext);
  
  // react hook form
  const { register, handleSubmit, watch, formState: { errors }, control, reset } = useForm();
  const { isDirty, isValid } = useFormState({
    control
  });

  // onsubmit button handler
  const onSubmit = (data) => {
    var pumpDetails = JSON.stringify({
      "name": data.name,
      "user_id": data.user_id.value,
      "location": data.location,
      "parent_id": data.parent_id.value,
      "node_id": props.selectedNode.value,
      "project_id": VARIABLES.project_id,
      "type": "pump",
      "pin_number" : data.pin_number.value,
      "mqtt_topic": `${VARIABLES.subdomain}/${VARIABLES.project_id}/${props.selectedNode.value}/P_${data.pin_number.value}/values`,
      "input_output": "O",
      "updated_by_id" : LoggedId
    });
    console.log(pumpDetails)

    var config = {
      method: 'patch',
      url: VARIABLES.url + `/api/entity-details/${props.editedPump.id}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: pumpDetails
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        {privileges.includes("superadmin_admin") && swal({
          icon : "success",
          title: "Pump Updated Successfully",
          showConfirmButton: false
        }).then(() => props.apiPumpDetails(),apiPumpDetails())}

        {privileges.includes("plantowner") && swal({
          icon : "success",
          title: "Pump Updated Successfully",
          showConfirmButton: false
        }).then(() => props.apiPumpPlantOwnerDetails(), apiPumpPlantOwnerDetails())}
      })
      .catch((error) => {
        console.log(error);
        if(error.response.status === 400) {
          swal({
            icon: 'error',
            title : "The Pin number is already taken for this node",
            text : error.response.data.message,
            showConfirmButton: false
          })
        } else {
          swal({
            icon: 'error',
            title: 'Failed to update Pump',
            showConfirmButton: false
          })
        }
      });
      reset();
  };

  // Prepopulated values of particular field
  useEffect(() => {
    if (props.editedPump) {
      console.log(props.editedPump)

      var defaultValues = {}
      defaultValues.name = props.editedPump.name
      defaultValues.location = props.editedPump.location
      defaultValues.pin_number = props.editedPump.pin_number

      const getNode = {
        value: props.editedPump.node_id,
        label: props.editedPump.node_id
      }
      defaultValues.node_id = getNode
      props.setSelectedNode(getNode)

      const getUserName = {
        value: props.editedPump.user.id,
        label: props.editedPump.user.user.username
      }
      defaultValues.user_id = getUserName

      if (props.editedPump.location === null) {
        console.log("hi")
      }

      const getParentId = props.plantDetails.find((id) => {
        return props.editedPump.parent_id == id.value
      })
      console.log(getParentId)
      defaultValues.parent_id = getParentId

      const splitMqtt = props.editedPump.mqtt_topic.split("/")
      const getPinNumber = splitMqtt[3].split("_")
      // defaultValues.pin_number = getPinNumber[1]

      const getPinNumberData = {
        value: getPinNumber[1],
        label: getPinNumber[1]
      }
      defaultValues.pin_number = getPinNumberData

      reset(defaultValues)
    }
  }, [props.editedPump])


  return (
    <>
      {/* <!-- Modal --> */}
      <div className="modal fade" id="editPump" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Edit Pump</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='mb-3'>
                  <label className="form-label">Name <span className="required_field">*</span></label>
                  <input className="form-control" type="text" placeholder="Enter the Pump Name" {...register("name", { required: true })}></input>
                  {errors.name && <span className='required_field'>Pump name is required</span>}
                </div>

                <div className="mb-3">
                  <label className="form-label">User <span className="required_field">*</span></label>
                  <Controller
                    render={({ field }) => <Select {...field} options={props.userdetails} />}
                    defaultValue=""
                    rules={{ required: true }}
                    name="user_id"
                    control={control} />
                    {errors.user_id && <span className='required_field'>User Id is required</span>}
                </div>

                <div className='mb-3'>
                  <label className="form-label">Location</label>
                  <input className="form-control" type="text" placeholder="Enter location" {...register("location")}></input>
                </div>

                <div className="mb-3">
                  <label className="form-label">Plant <span className="required_field">*</span></label>
                  <Controller
                    render={({ field }) => <Select {...field} options={props.plantDetails} />}
                    defaultValue=""
                    rules={{ required: true }}
                    name="parent_id"
                    control={control} />
                    {errors.parent_id && <span className='required_field'>Plant Id is required</span>}
                </div>

                <div className="mb-3">
                  <label className="form-label">Node Id <span className="required_field">*</span></label>
                  <Controller
                    render={({ field }) => <Select 
                    {...field} 
                    value={props.selectedNode}
                    onChange={(data) => {
                      props.setSelectedNode(data)
                      field.onChange(data)
                    }}
                    options={props.nodeData}
                    noOptionsMessage={() => 'No Nodes Avaliable.'} />}
                    rules={{ required: true }}
                    defaultValue=""
                    name="node_id"
                    control={control} />
                    {errors.node_id && <span className='required_field'>Node Id is required</span>}
                </div>

                <div className="mb-3">
                  <label className="form-label">Pin Number <span className="required_field">*</span></label>
                  <Controller
                    render={({ field }) => <Select {...field} 
                    options={props.options}
                    noOptionsMessage={() => 'No Pins Avaliable. Please select Node first'} />}
                    defaultValue=""
                    rules={{ required: true }}
                    name="pin_number"
                    control={control} />
                    {errors.pin_number && <span className='required_field'>Pin Number is required</span>}
                </div>
                
                <div className="modal-footer">
                  {isValid === true ?  <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">Submit</button> : <button type="submit" className="btn btn-primary">Submit</button>}
                  {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PumpManagementEdit