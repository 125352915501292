import axios from 'axios';
import React, { useContext } from 'react'
import { Controller, useForm,useFormState } from "react-hook-form";
import Select from 'react-select'
import { VARIABLES } from '../../Constant';
import swal from 'sweetalert';
import secureLocalStorage from "react-secure-storage";
import { PumpDetailsContext } from '../../Context/PumpDetailsContext';

const ClientPlantOwnerCreate = (props) => {
  const {setPumpDetails} = useContext(PumpDetailsContext)
  const privileges = secureLocalStorage.getItem('privileges')
  const loggedId = secureLocalStorage.getItem('loggedId')
  // react hook form
  const { register, handleSubmit, watch, formState: { errors }, control, reset } = useForm();
  const { isDirty, isValid } = useFormState({
    control
  });

  // onSubmit handler
  const onSubmit = (data) => {
    var pumpDetails = JSON.stringify({
      "name": data.name,
      "user_id": data.user_id.value,
      "location": data.location,
      "type": "plant",
      "created_by_id" : loggedId
    });
    console.log(pumpDetails)

    var config = {
      method: 'post',
      url: VARIABLES.url + "/api/entity-details",
      headers: {
        'Content-Type': 'application/json'
      },
      data: pumpDetails
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        { privileges.includes("superadmin") && swal({
            icon: "success",
            title: 'Plant Created Successfully',
            showConfirmButton: false
          }).then(() => props.apiPlantDetails()) }

        { privileges.includes("admin") && swal({
            icon: "success",
            title: 'Plant Created Successfully',
            showConfirmButton: false
          }).then(() => props.apiPlantAdminDetails()) }
          setPumpDetails(null)
      })
      .catch((error) => {
        console.log(error);
        swal({
          icon: 'error',
          title: 'Failed to create Plant',
          showConfirmButton: false
        })
      });
    reset();
  };

  return (
    <>
      {/* <!-- Modal --> */}
      <div className="modal fade" id="createPlant" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Create Plant</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='mb-3'>
                  <label className="form-label">Name <span className="required_field">*</span></label>
                  <input className="form-control" type="text" placeholder="Enter the Plant Name" {...register("name", { required: true })}></input>
                  {errors.name && <span className='required_field'>Plant name is required</span>}
                </div>

                <div className="mb-3">
                  <label className="form-label">User <span className="required_field">*</span></label>
                  <Controller
                    render={({ field }) => <Select {...field} options={props.userDetails} />}
                    defaultValue=""
                    rules={{ required: true }}
                    name="user_id"
                    control={control} />
                    {errors.user_id && <span className='required_field'>User Id is required</span>}
                </div>

                <div className='mb-3'>
                  <label className="form-label">Location</label>
                  <input className="form-control" type="text" placeholder="Enter location" {...register("location")}></input>
                </div>

                <div className="modal-footer">
                  {isValid === true ?  <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">Submit</button> : <button type="submit" className="btn btn-primary">Submit</button>}
                  {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ClientPlantOwnerCreate