import React from 'react'
import OverviewDashboard from './OverviewDashboard'
import Heading from '../Base/Heading'
import WaterTankNotification from './WaterTankNotification'

const Dashboard = (props) => {
  return (
    <>
      <Heading heading="Welcome to Dashboard" subheading="Overview of Admin's and Client Dashboard and Summary of all plant owners are here." />
      <OverviewDashboard handleSidebar={props.handleSidebar} />
      <WaterTankNotification handleSidebar={props.handleSidebar} />
    </>
  )
}

export default Dashboard