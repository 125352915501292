import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Controller, useForm,useFormState } from "react-hook-form";
import Select from 'react-select'
import Swal from 'sweetalert2';
import { VARIABLES } from '../../Constant';
import secureLocalStorage from "react-secure-storage";
import swal from 'sweetalert';

const ONOFF = [
  {label:"ON",value:true},
  {label:"OFF",value:false}
]

const PumpCreateScheduler = (props) => {
  // local storage saved variables
  const loggedId = secureLocalStorage.getItem('loggedId')

  // React hook form
  const { register, handleSubmit, watch, formState: { errors }, control,reset } = useForm();
  const { isDirty, isValid } = useFormState({
    control
  });

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(props.weekDays.map(li => li.value));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = e => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };

  
  console.log(isCheck);

  const weekdaysCheckbox = props.weekDays.map(({ value, label }) => {
    return (
      <>
        <input
          className='form-check-input checkbox_days'
          key={value}
          type="checkbox"
          name={label}
          id={value}
          onChange={handleClick}
          checked={isCheck.includes(value)}
        />
        {label}
      </>
    );
  });

  // onsubmit handler
  const onSubmit = (data) => {
    // var string = JSON.stringify(isCheck);
    // string.replace (/'/g,'');
    var zoneDetails = JSON.stringify({
      "entity_id": data.entity_id.value,
      "scheduler_day": isCheck,
      "scheduler_time": data.scheduler_time,
      "is_on": data.is_on.value,
      "created_by_id": loggedId
    });
    console.log(zoneDetails)

    var config = {
      method: 'post',
      url: VARIABLES.url + '/api/scheduler-details',
      headers: {
        'Content-Type': 'application/json'
      },
      data: zoneDetails
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        swal({
          position: 'center',
          icon: 'success',
          title: 'Clicked Update button to Set Scheduler',
          showConfirmButton: true
        }).then(() => props.apiSchedulerData())
      })
      .catch((error) => {
        console.log(error);
        swal({
          position: 'center',
          icon: 'error',
          title: 'Failed to create Schedular Set',
          showConfirmButton: true
        })
      });
    console.log(data)
    reset();
    setIsCheck("")
  };
  
  return (
    <>
      {/* <!-- Modal --> */}
      <div className="modal fade" id="setSchedule" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Set Scheduler</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                  <label className="form-label">Entity <span className="required_field">*</span></label>
                  <Controller
                    render={({ field }) => <Select {...field} options={props.pumps} />}
                    defaultValue=""
                    rules={{ required: true }}
                    name="entity_id"
                    control={control} />
                    {errors.entity_id && <span className='required_field'>Entity Name is required</span>}
                </div>

                <div className='mb-3'>
                  <label className="form-check-label">Days <span className="required_field">*</span></label> <br />
                  <input
                    className='form-check-input checkbox_days'
                    type="checkbox"
                    name="selectAll"
                    id="selectAll"
                    onChange={handleSelectAll}
                    checked={isCheckAll}
                  />
                  Select All
                  {weekdaysCheckbox}
                </div>

                <div className='mb-3'>
                  <label className="form-label">Time <span className="required_field">*</span></label>
                  <input className="form-control" type="time" placeholder="Enter the Time" {...register("scheduler_time", { required: true })} />
                  {errors.scheduler_time && <span className='required_field'>Scheduler Time is required</span>}
                </div>

                <div className='mb-3'>
                  <label className="form-label">On/Off <span className="required_field">*</span></label>
                  <Controller
                    render={({ field }) => <Select {...field} options={ONOFF} />}
                    defaultValue=""
                    rules={{ required: true }}
                    name="is_on"
                    control={control} />
                    {errors.is_on && <span className='required_field'>On/Off is required</span>}
                </div>

                <div className="modal-footer">
                  {isValid === true ?  <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">Submit</button> : <button type="submit" className="btn btn-primary">Submit</button>}
                  {/* <button type="submit" className="btn btn-primary">Save</button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PumpCreateScheduler