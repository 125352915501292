import React, { useEffect, useState,useContext } from 'react'
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from '../../Constant';
import axios from 'axios';
import TankStatusCreate from '../TankStatus/TankStatusCreate';
import TankStatusEdit from '../TankStatus/TankStatusEdit';
import TankStatusTable from '../TankStatus/TankStatusTable';
import TankRender from './TankRender';
import { PumpDetailsContext } from '../../Context/PumpDetailsContext';

const TankDetails = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const privileges = secureLocalStorage.getItem('privileges')
  const pumpId = secureLocalStorage.getItem('pump_id')
  const plantId = secureLocalStorage.getItem('plant_id')
  const userId = secureLocalStorage.getItem('userID')

  const {
    setPumpDetails,pumpDetails} = useContext(PumpDetailsContext);

  const [tankDetails, setTankDetails] = useState([])
  console.log(tankDetails)

  const [userdetails, setUserDetails] = useState([]);

  const [pumpDetailsforTank, setPumpDetailsforTank] = useState([])
  console.log(pumpDetailsforTank)

  // After selecting the node the node value stored in "selectedNode" state
  const [selectedNode, setSelectedNode] = useState([])
  console.log(selectedNode)

  const [nodeData, setNodeData] = useState([])

  // Raw data of (node and pins avaliable and unavaliable)
  const [nodePinData, setNodePinData] = useState([])
  console.log(nodePinData)

  // options to be send in React Select dropdown for pins
  const [options, setOptions] = useState([])


  const [editedTankDetails, setEditedTankDetails] = useState(null)

  const onChangeSidebar = (section) => {
    props.handleSidebar(section)
  }

  const apiUserDetails = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/userdetails?role=plant owner');
      setUserDetails(
        res.data.map((userData) => ({
          value: userData.id,
          label: userData.user.username
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };


  // provisin for plant owner to get tha tank details
  const getTankDetailsforPlantOWner = async () => {
    try {
      setIsLoading(true)
      const res = await axios.get(VARIABLES.url + "/api/entity-details?type=tank&parent_id=" + pumpDetails);
      setTankDetails(res.data);
      console.log(res.data);
      setIsLoading(false)
    } catch (error) {
      console.log(error);
    }
  };

  // provison for plant owner
  const apiTankPlantDropdownDetails = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/entity-details?type=pump&parent_id=' + plantId);
      setPumpDetailsforTank(
        res.data.map((userData) => ({
          value: userData.id,
          label: userData.name
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  // provision for plantowner to populate pump dropdown in tank form  
  const apiPumptoTankDetails = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/entity-details?type=pump&user_id=' + userId);
      setPumpDetailsforTank(
        res.data.map((userData) => ({
          value: userData.id,
          label: userData.name
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const apiNodeData = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/node-details');
      setNodePinData(res.data)
      setNodeData(res.data.map((node) => ({
        value: node.name,
        label: node.name
      })))
      console.log(res.data)
    } catch (error) {
      console.log(error);
    }
  };

  // to get specific pin of selected node
  const getNodePin = () => {
    if (selectedNode.length === 0) {
      console.log("hi")
    } else {
      const selectedPins = nodePinData.find((pin) => pin.name === selectedNode.label)
      console.log(selectedPins)
      const availableOptions = selectedPins.input_pin_available.map((value) => ({
        value: value,
        label: value,
        isDisabled: false, // input_pin_available should be enabled
      }));

      const unavailableOptions = selectedPins.input_pin_unavailable.map((value) => ({
        value: value,
        label: value,
        isDisabled: true, // input_pin_unavailable should be disabled
      }));
      setOptions(availableOptions.concat(unavailableOptions))
    }
  }

  const editTankDetails = (id) => {
    const getTankDetails = tankDetails.find((tank) => {
      return tank.id === id
    })
    console.log(getTankDetails);
    setEditedTankDetails(getTankDetails);
  }

  useEffect(() => {
    apiUserDetails();
    apiNodeData();
    getNodePin();
    { privileges.includes("superadmin_admin") && apiTankPlantDropdownDetails() }
    
    { privileges.includes("plantowner") && apiPumptoTankDetails() }
  }, [selectedNode])

  useEffect(() =>{
    {pumpDetails && getTankDetailsforPlantOWner(); }
  },[pumpDetails])

  return (
    <>
      <TankStatusCreate
        userdetails={userdetails}
        getTankDetailsforPlantOWner={getTankDetailsforPlantOWner}
        pumpDetailsforTank={pumpDetailsforTank}
        nodeData={nodeData}
        selectedNode={selectedNode}
        setSelectedNode={setSelectedNode}
        options={options} />

      <TankStatusEdit
        userdetails={userdetails}
        getTankDetailsforPlantOWner={getTankDetailsforPlantOWner}
        editedTankDetails={editedTankDetails}
        pumpDetailsforTank={pumpDetailsforTank}
        nodeData={nodeData}
        selectedNode={selectedNode}
        setSelectedNode={setSelectedNode}
        options={options} />


      <div className='row'>
        {isLoading ? <div className='card-inner'>Loading...</div> :
          <>
          {
            Array.isArray(tankDetails) ? 
            <>
            {tankDetails?.length === 0 &&
              <div className='row'>
                <div className='col-sm-12' style={{ marginTop: "20px" }}>
                  <div className='card'>
                    <div className='card-inner'>
                      Tanks are not created
                    </div>
                  </div>
                </div>
              </div>
            }
            {
              tankDetails?.map((tank) => {
                return <TankRender
                  editTankDetails={editTankDetails}
                  isLoading={isLoading}
                  tankDetails={tankDetails}
                  tank={tank}
                  setTankDetails={setTankDetails}
                  handleSidebar={props.handleSidebar}
                  setTankHandleDetails={props.setTankHandleDetails} />
              })
            }
            </> : <div className='row'>
                <div className='col-sm-12' style={{ marginTop: "20px" }}>
                  <div className='card'>
                    <div className='card-inner'>
                      Tanks are not created
                    </div>
                  </div>
                </div>
              </div>
          }
          </>
        }
      </div>

      
    </>
  )
}

export default TankDetails