import axios from 'axios';
import React, { useEffect } from 'react'
import { Controller, useForm,useFormState } from "react-hook-form";
import Select from 'react-select'
import Swal from 'sweetalert2';
import { VARIABLES } from '../../Constant';
import  secureLocalStorage  from  "react-secure-storage";
import swal from 'sweetalert';

const ZoneManagementEdit = (props) => {
  // get stored local storage data from "loggedId"
  const LoggedId = secureLocalStorage.getItem("loggedId")

  // react hook form
  const { register, handleSubmit, watch, formState: { errors }, control,reset } = useForm();
  const { isDirty, isValid } = useFormState({
    control
  });

  // onSubmit handler to edit the "Zone"
  const onSubmit = (data) => {
    var zoneDetails = JSON.stringify({
      "name": data.name,
      "user_id": data.user_id.value,
      "location": data.location,
      "parent_id": data.parent_id.value,
      "node_id": props.selectedNode.value,
      "project_id": VARIABLES.project_id,
      "type": "zone",
      "pin_number" : data.pin_number.value,
      "mqtt_topic": `${VARIABLES.subdomain}/${VARIABLES.project_id}/${props.selectedNode.value}/P_${data.pin_number.value}/values`,
      "input_output": "O",
      "updated_by_id" : LoggedId
    });
    
    var config = {
      method: 'patch',
      url: VARIABLES.url+`/api/entity-details/${props.editedZone.id}`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : zoneDetails
    };
    
    axios(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      swal({
        icon : "success",
        title: "Zone Updated Successfully",
        showConfirmButton: false,
      }).then(() => props.apiZoneDetails())
    })
    .catch((error) => {
      console.log(error);
      if(error.response.status === 400) {
        Swal.fire(
          "The Pin number is already taken for this node",
          error.response.data.message,
          'error'
        )
      } else {
        swal({
          icon: 'error',
          title: 'Failed to update Zone',
          showConfirmButton: false,
        })
      }
    });
    console.log(data)
    reset();
  };

  // prepopulate the values of particular "zone" in form
  useEffect(() => {
    if (props.editedZone) {
      console.log(props.editedZone)

      var defaultValues = {}
      defaultValues.name = props.editedZone.name
      defaultValues.location = props.editedZone.location

      const getNode = {
        value: props.editedZone.node_id,
        label: props.editedZone.node_id
      }
      defaultValues.node_id = getNode
      props.setSelectedNode(getNode)

      const getUserName = {
        value: props.editedZone.user.id,
        label: props.editedZone.user.user.username
      }
      defaultValues.user_id = getUserName

      if (props.editedZone.location === null) {
        console.log("hi")
      }

      const getParentId = props.zoneDetails.find((id) => {
        return props.editedZone.parent_id == id.value
      })
      console.log(getParentId)
      defaultValues.parent_id = getParentId

      const splitMqtt = props.editedZone.mqtt_topic.split("/")
      const getPinNumber = splitMqtt[3].split("_")
      // defaultValues.pin_number = getPinNumber[1]

      const getPinNumberData = {
        value: getPinNumber[1],
        label: getPinNumber[1]
      }
      defaultValues.pin_number = getPinNumberData

      reset(defaultValues)
    }
  }, [props.editedZone])

  return (
    <>
      {/* <!-- Modal --> */}
      <div className="modal fade" id="editZone" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Edit Zone</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className='mb-3'>
                  <label className="form-label">Zone Name <span className="required_field">*</span></label>
                  <input className="form-control" type="text" placeholder="Enter the Zone Name" {...register("name", { required: true })} />
                  {errors.name && <span className='required_field'>Zone name is required</span>}
                </div>

                <div className="mb-3">
                  <label className="form-label">User <span className="required_field">*</span></label>
                  <Controller
                    render={({ field }) => <Select {...field} options={props.userdetails} />}
                    defaultValue=""
                    rules={{ required: true }}
                    name="user_id"
                    control={control} />
                    {errors.user_id && <span className='required_field'>User Id is required</span>}
                </div>

                <div className='mb-3'>
                  <label className="form-label">Location</label>
                  <input className="form-control" type="text" placeholder="Enter the Location" {...register("location")} />
                </div>

                <div className='mb-3'>
                  <label className="form-label">Pump <span className="required_field">*</span></label>
                  <Controller
                    render={({ field }) => <Select {...field} options={props.zoneDetails} />}
                    defaultValue=""
                    rules={{ required: true }}
                    name="parent_id"
                    control={control} />
                    {errors.parent_id && <span className='required_field'>Pump Id is required</span>}
                </div>

                <div className="mb-3">
                  <label className="form-label">Node Id <span className="required_field">*</span></label>
                  <Controller
                    render={({ field }) => <Select {...field} 
                    options={props.nodeData}
                    value={props.selectedNode}
                    onChange={(data) => {
                      props.setSelectedNode(data)
                      field.onChange(data)
                    }}
                    noOptionsMessage={() => 'No Nodes Avaliable.'} />}
                    defaultValue=""
                    rules={{ required: true }}
                    name="node_id"
                    control={control} />
                    {errors.node_id && <span className='required_field'>Node Id is required</span>}
                </div>

                <div className="mb-3">
                  <label className="form-label">Pin Number <span className="required_field">*</span></label>
                  <Controller
                    render={({ field }) => <Select {...field} 
                    options={props.options}
                    noOptionsMessage={() => 'No Pins Avaliable. Please select Node first'} />}
                    defaultValue=""
                    rules={{ required: true }}
                    name="pin_number"
                    control={control} />
                    {errors.pin_number && <span className='required_field'>Pin Number is required</span>}
                </div>

                <div className="modal-footer">
                  {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                  {isValid === true ?  <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">Submit</button> : <button type="submit" className="btn btn-primary">Submit</button>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ZoneManagementEdit