import React, { useState,useContext } from 'react'
import { useEffect } from 'react';
import  secureLocalStorage  from  "react-secure-storage";
import { VARIABLES } from '../../Constant';
import axios from 'axios'
import { useGlobalContext } from '../Context';
import { PumpDetailsContext } from '../../Context/PumpDetailsContext';

const Sidebar = (props) => {
  const privileges = secureLocalStorage.getItem('privileges')
  const userId = secureLocalStorage.getItem('userID')
  const plantId = secureLocalStorage.getItem('plant_id')
  console.log(plantId)

  // isActive is special for other fields in sidebar
  const [isActive, setIsActive] = useState(privileges[0])
  console.log(isActive)

  const {
    sidebarPumps,
    setPumpDetails,
    isPumpActive,
    apiPumpDetails,
    apiPumpPlantOwnerDetails,
    setIsPumpActive} = useContext(PumpDetailsContext);

  const onChangeSidebar = (section,pump_id,pump_name) => {
    props.handleSidebar(section)
    setPumpDetails(pump_id)
    setIsActive(section)
    setIsPumpActive(pump_name)
    // setChecked(null)
  }

  useEffect(() => {
    {privileges.includes("plantowner") && apiPumpPlantOwnerDetails()} // plant owner
    // {privileges.includes("superadmin_admin") && plantId && apiPumpDetails() }  //admin and super admin
  },[userId])

  return (
    <>
      <div className={`nk-sidebar nk-sidebar-fixed is-light ${props.showSidebar ? "nk-sidebar-active" : ""}`} data-content="sidebarMenu">
        <div className="nk-sidebar-element nk-sidebar-head sidebar-background">
          <div className="nk-sidebar-brand">
            <a href="javascript:void(0)" className="logo-link nk-sidebar-logo">
              <img className="logo-light logo-img" src="./images/zoom-solar-logo.png" srcSet="./images/zoom-solar-logo.png 2x" alt="zoom solar logo" />
              <img className="logo-dark logo_img logo-img" src="./images/zoom-solar-logo.png" srcSet="./images/zoom-solar-logo.png 2x" alt="zoom solar logo" />
              <img className="logo-small logo-images logo-img-small" src="./images/zoom-solar-logo.png" srcSet="./images/zoom-solar-logo.png 2x" alt="zoom solar logo" />
            </a>
          </div>
          <div className="nk-menu-trigger me-n2">
            <a href="javascript:void(0)" onClick={props.hideSidebar} className="nk-nav-toggle nk-quick-nav-icon d-xl-none" data-target="sidebarMenu"><em className="icon ni ni-arrow-left"></em></a>
            {/* <a href="javascript:void(0)" className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex" data-target="sidebarMenu"><em className="icon ni ni-menu"></em></a> */}
          </div>
        </div>
        {/* <!-- .nk-sidebar-element --> */}
        <div className="nk-sidebar-element">
          <div className="nk-sidebar-content">
            <div className="nk-sidebar-menu" data-simplebar>
              <ul className="nk-menu">
                {/* <!-- .nk-menu-item --> */}
                {/* SUperadmin Dashboard */}
                {privileges.includes("dashboard") ? <li className="nk-menu-item">
                  <a href="javascript:void(0)" className={`nk-menu-link ${isActive === "dashboard" ? "active" : ""}`} onClick={() => onChangeSidebar("dashboard")}>
                    <span className="nk-menu-icon"><em className="icon ni ni-dashboard"></em></span>
                    <span className="nk-menu-text">Dashboard</span>
                  </a>
                </li> : ""}
                {/* <!-- .nk-menu-item --> */}

                {privileges.includes("pump_details") ? 
                   sidebarPumps.map((pump) => {
                     return <li className="nk-menu-item">
                     <a href="javascript:void(0)" className={`nk-menu-link ${isPumpActive === pump.name ? "active" : ""}`} onClick={() => onChangeSidebar("pump_details",pump.id,pump.name)}>
                       <span className="nk-menu-icon"><em class="icon ni ni-cloud-fill"></em></span>
                       <span className="nk-menu-text">{pump.name}</span>
                     </a>
                   </li>
                   }) : ""}

                {privileges.includes("user_management") ? <li className="nk-menu-item">
                  <a href="javascript:void(0)" className={`nk-menu-link ${isActive === "user_management" ? "active" : ""}`} onClick={() => onChangeSidebar("user_management")}>
                    <span className="nk-menu-icon"><em className="icon ni ni-user-fill"></em></span>
                    <span className="nk-menu-text">User Management</span>
                  </a>
                </li> : ""}
              </ul>
              {/* <!-- .nk-menu --> */}
            </div>
            {/* <!-- .nk-sidebar-menu --> */}
          </div>
          {/* <!-- .nk-sidebar-content --> */}
        </div>
        {/* <!-- .nk-sidebar-element --> */}
      </div>
    </>
  )
}

export default Sidebar