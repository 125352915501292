import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ClientPlantOwnerCreate from '../ClientPlantOwner/ClientPlantOwnerCreate'
import ClientPlantOwnerEdit from "../ClientPlantOwner/ClientPlantOwnerEdit"
import { VARIABLES } from '../../Constant';
import secureLocalStorage from "react-secure-storage";
import AllPlantOwnerCards from './AllPlantOwnerCards';

const AllPlantOwner = (props) => {
  // isLoading state to load the data
  const [isLoading, setIsLoading] = useState(false)
  // get Privilleges from the localstorage
  const privileges = secureLocalStorage.getItem('privileges')

  // after clicking on "admin card view button" the id of admin stored in admin_id in local storage
  const adminId = secureLocalStorage.getItem('admin_id')

  // userID gets stored in local storage
  const userId = secureLocalStorage.getItem('userID')

  // plants details stored in "Plants" state
  const [plants, setPlants] = useState([])
  console.log(plants)

  // admin user details of role gets stored in "userDetails" state
  const [userDetails, setUserDetails] = useState([])
  console.log(userDetails)

  // After clicking on edited button the specific id "details" gets stored in "editedPlant"
  const [editedPlant,setEditedPlant] = useState(null)

  // Onchnage handle to swtich from one page to anathor
  const onChangeSidebar = (section, plant_id) => {
    props.handleSidebar(section)
  }

  // api call to stored the user details by role = admin in "userdetails" state
  // passed as dropdown in create plant form and edit form
  const getUserDetails = async () => {
    try {
      setIsLoading(true)
      const res = await axios.get(VARIABLES.url + "/api/userdetails?role=admin");
      setUserDetails(
        res.data.map((userData) => ({
          value: userData.id,
          label: userData.user.username
        }))
      );
      setIsLoading(false)
    } catch (error) {
      console.log(error);
    }
  };

  // For "super admin" provision => to get hierarchy according to the flow of superadmin flow
  // After clicking on admin card view button the id of admin gets stored in local storage and passed in below url
  const apiPlantDetails = async () => {
    try {
      setIsLoading(true)
      const res = await axios.get(VARIABLES.url + '/api/entity-details?type=plant&user_id=' + adminId);
      setPlants(res.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error);
    }
  };

  // For "admin" provision => to get hierarchy according to the flow of admin flow
  // After logged in as admin the useeId of admin passed in below url
  const apiPlantAdminDetails = async () => {
    try {
      setIsLoading(true)
      const res = await axios.get(VARIABLES.url + '/api/entity-details?type=plant&user_id=' + userId);
      setPlants(res.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error);
    }
  };

  // after clicking on edit button it finds particular match id and stored details in "editedPlant" state
  const editPlant = (id) => {
    const getEditedPlant = plants.find((plant) => {
      return plant.id === id
    })
    console.log(getEditedPlant);
    setEditedPlant(getEditedPlant);
  }

  useEffect(() => {
    getUserDetails();
    { privileges.includes("superadmin") && apiPlantDetails() } // for super admin flow
    { privileges.includes("admin") && apiPlantAdminDetails() } // for admin flow
  }, [])

  return (
    <>
      {/* Arrow keys to navigate back */}
      {privileges.includes("back_admin") && <div className='nk-block-head' style={{paddingBottom:"1.25rem"}}>
        <div class="nk-block-head-content">
          <a href="javascript:void(0)" class="btn btn-outline-light bg-white d-none d-sm-inline-flex" onClick={()=> onChangeSidebar("Admin Overview")}><em
            class="icon ni ni-arrow-left"></em><span>Back</span></a>
          <a href="javascript:void(0)"
            class="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none" onClick={()=> onChangeSidebar("Admin Overview")}><em
              class="icon ni ni-arrow-left"></em></a>
        </div>
      </div>}

      {/* Heading of Plant Owner */}
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title page-title">Client's Overview</h4>
          </div>
          {/* <!-- .nk-block-head-content --> */}
          {privileges.includes("add_plant") && <div class="nk-block-head-content">
            <div class="toggle-wrap nk-block-tools-toggle">
              <div class="toggle-expand-content" data-content="pageMenu">
                <ul class="nk-block-tools g-3">
                  <li class="nk-block-tools-opt d-none d-sm-block">
                    <a href='javascript:void(0)' class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#createPlant"><em class="icon ni ni-plus"></em><span>Add Plant</span></a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- .toggle-wrap --> */}
          </div>}
        </div>
      </div>

      <AllPlantOwnerCards 
      editPlant={editPlant}
      isLoading={isLoading}
      plants={plants}
      setPlants={setPlants}
      handleSidebar={props.handleSidebar} />

      <ClientPlantOwnerCreate 
      userDetails={userDetails}
      apiPlantDetails={apiPlantDetails}
      apiPlantAdminDetails={apiPlantAdminDetails}
       />

      <ClientPlantOwnerEdit 
      editedPlant={editedPlant}
       userDetails={userDetails}
       apiPlantDetails={apiPlantDetails}
      apiPlantAdminDetails={apiPlantAdminDetails} />
    </>
  )
}

export default AllPlantOwner