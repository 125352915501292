import React from 'react'
import  secureLocalStorage  from  "react-secure-storage";

const AllAdminStatusCard = (props) => {
  const privileges = secureLocalStorage.getItem('privileges')
  // Onchnage handle to swtich from one page to anathor
  // AFter clicking on "Plant" button "admin Id" stored in secure local storage
  const onChangeSidebar = (section, admin_id) => {
    secureLocalStorage.setItem('admin_id', admin_id);
    props.handleSidebar(section)
  }

  return (
    <>
      <div className='row g-gs'>
        {props.isLoading ? <div className='card-inner'>Loading...</div> :
          <>
            {props.admins.length === 0 &&
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='card'>
                    <div className='card-inner'>
                      Admins are not created
                    </div>
                  </div>
                </div>
              </div>
            }
            {props.admins.map((admin) => {
              const firstname = admin.user.first_name.charAt(0)
              const lastname = admin.user.last_name.charAt(0)
              return <div className="col-sm-6 col-lg-4 col-xxl-3">
                <div className="card">
                  <div className="card-inner">
                    <div className="team">
                      <div className="user-card user-card-s2">
                        {/* <div className="user-avatar md bg-primary">
                          <span>{firstname}{lastname}</span>
                        </div> */}
                        <div className="user-info">
                          <h6> {admin.user.username} </h6>
                          <span className="sub-text">{admin.user.email} </span>
                        </div>
                      </div>
                      {privileges.includes("view_plant_details") && <div className="team-view">
                        <a href="javascript:void(0)" className="btn btn-round btn-outline-light w-150px" onClick={() => onChangeSidebar("Plant Overview", admin.id)}><span>Plant</span></a>
                      </div>}
                    </div>
                    {/* <!-- .team --> */}
                  </div>
                  {/* <!-- .card-inner --> */}
                </div>
                {/* <!-- .card --> */}
              </div>
            })}
          </>
        }
        {/* <!-- .col --> */}
      </div>
    </>
  )
}

export default AllAdminStatusCard