import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { Controller, useForm,useFormState } from "react-hook-form";
import Select from 'react-select'
import Swal from 'sweetalert2';
import { VARIABLES } from '../../Constant';
import swal from 'sweetalert';
import secureLocalStorage from "react-secure-storage";

const ClientPlantOwnerEdit = (props) => {
  const privileges = secureLocalStorage.getItem('privileges')
  const loggedId = secureLocalStorage.getItem('loggedId')
  
  const { register, handleSubmit, watch, formState: { errors }, control, reset } = useForm();
  const { isDirty, isValid } = useFormState({
    control
  });

  const onSubmit = (data) => {
    var pumpDetails = JSON.stringify({
      "name": data.name,
      "user_id": data.user_id.value,
      "location": data.location,
      "type": "plant",
      "updated_by_id" : loggedId
    });

    var config = {
      method: 'patch',
      url: VARIABLES.url + `/api/entity-details/${props.editedPlant.id}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: pumpDetails
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        {
          privileges.includes("superadmin") && swal({
            icon: "success",
            title: 'Plant Updated Successfully',
            showConfirmButton: false
          }).then(() => props.apiPlantDetails())
        }

        {
          privileges.includes("admin") && swal({
            icon: "success",
            title: 'Plant Updated Successfully',
            showConfirmButton: false
          }).then(() => props.apiPlantAdminDetails())
        }
      })
      .catch((error) => {
        console.log(error);
        swal({
          icon: 'error',
          title: 'Failed to update Plant',
          showConfirmButton: false
        })
      });
    reset();
  };

  useEffect(() => {
    if (props.editedPlant) {
      console.log(props.editedPlant)

      var defaultValues = {}
      defaultValues.name = props.editedPlant.name
      defaultValues.location = props.editedPlant.location

      const getUserName = {
        value: props.editedPlant.user.id,
        label: props.editedPlant.user.user.username
      }
      defaultValues.user_id = getUserName

      if (props.editedPlant.location === null) {
        console.log("hi")
      }

      reset(defaultValues)
    }
  }, [props.editedPlant])

  return (
    <>
      {/* <!-- Modal --> */}
      <div className="modal fade" id="editPlant" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Edit Plant</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='mb-3'>
                  <label className="form-label">Name <span className="required_field">*</span></label>
                  <input className="form-control" type="text" placeholder="Enter the Plant Name" {...register("name", { required: true })}></input>
                  {errors.name && <span className='required_field'>Plant name is required</span>}
                </div>

                <div className="mb-3">
                  <label className="form-label">User <span className="required_field">*</span></label>
                  <Controller
                    render={({ field }) => <Select {...field} options={props.userDetails} />}
                    defaultValue=""
                    rules={{ required: true }}
                    name="user_id"
                    control={control} />
                    {errors.user_id && <span className='required_field'>User Id is required</span>}
                </div>

                <div className='mb-3'>
                  <label className="form-label">Location</label>
                  <input className="form-control" type="text" placeholder="Enter location" {...register("location")}></input>
                </div>

                <div className="modal-footer">
                  {isValid === true ?  <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">Submit</button> : <button type="submit" className="btn btn-primary">Submit</button>}
                  {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ClientPlantOwnerEdit