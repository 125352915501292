import axios from 'axios'
import React, { useEffect, useState } from 'react'
import UserDetailsCreate from './UserDetailsCreate'
import UserDetailsEdit from './UserDetailsEdit'
import UserDetailsTable from './UserDetailsTable'
import { VARIABLES } from '../../Constant';
import  secureLocalStorage  from  "react-secure-storage";

const UserDetails = () => {
  // Local storage variables
  const role_name = secureLocalStorage.getItem("role_name")
  const userID = secureLocalStorage.getItem("userID")
  const privileges = secureLocalStorage.getItem('privileges')

  // isLoading State
  const [isLoading,setIsLoading] = useState(false)

  // All userDetails stored in "userDetails" state
  const [userDetails,setUserDetails] = useState([])
  console.log(userDetails)

  // admin Details for "superior_id" for plant owner
  const [adminDropdown,setAdminDropdown] = useState([])

  // roles for "superadmin","admin","plantowner"
  const [roles,setRoles] = useState([])

  // Particular userDetails Data stored in "editedUserData" for pre-population
  const [editedUserData,setEditedUserData] = useState(null)

  // ToDo : 1. Can we use only single API instead of 2 different API (getUserDetails,getAdminRoleDetails)
  // to show all user details for "superadmin" role
  const getUserDetails = async () => {
    let url = VARIABLES.url+"/api/userdetails"
    console.log(url)
    try {
      setIsLoading(true)
      const res = await axios.get(url);
      setUserDetails(res.data);
      setIsLoading(false)
    } catch (error) {
      console.log(error);
    }
  };

  // to show specific admin => plant owner only in user Details table "admin role"
  const getAdminRoleDetails = async () => {
    try {
      setIsLoading(true)
      const res = await axios.get(VARIABLES.url+"/api/userdetails?user_id=" + userID);
      setUserDetails(res.data);
      setIsLoading(false)
    } catch (error) {
      console.log(error);
    }
  };

  // Role dropdown "superadmin","admin","plantowner"
  const getRole = async () => {
    try {
      const res = await axios.get(VARIABLES.url+"/api/role?role=" + role_name);
      setRoles(
        res.data.map((role) => ({
          value: role.id,
          label: role.name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  // api call to get the admin details role wise and store in state "adminDropdown" 
  // admin dropdown to give "superior id" to plant owner
  const apiAdminDropdown = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/userdetails?role=admin');
      setAdminDropdown(res.data.map((role) => ({
        value: role.id,
        label: role.user.username,
      })))
    } catch (error) {
      console.log(error);
    }
  };

  // Edit userDetails for particular id 
  const editUserDetails = (id) => {
    const getEditedUser = userDetails.find((user) => {
      return user.id === id
    })
    console.log(getEditedUser);
    setEditedUserData(getEditedUser);
  }
  
  useEffect(() => {
    {privileges.includes("superadmin") && getUserDetails()};
    {privileges.includes("admin_plantowner") && getAdminRoleDetails()};
    getRole();
    apiAdminDropdown();
  },[])

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">User Details</h3>
          </div>
          {/* <!-- .nk-block-head-content --> */}
          {privileges.includes("add_user") && <div className="nk-block-head-content">
            <div className="col-auto d-flex w-sm-100">
              <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addAdmin"><em class="icon ni ni-plus"></em><span>Add User</span></button>
            </div>
          </div>}
          {/* <!-- .nk-block-head-content --> */}
        </div>
        {/* <!-- .nk-block-between --> */}
      </div>

      <UserDetailsCreate 
      getUserDetails={getUserDetails}
      roles={roles}
      adminDropdown={adminDropdown}
      getAdminRoleDetails={getAdminRoleDetails} />

      <UserDetailsEdit editedUserData={editedUserData}
      getUserDetails={getUserDetails}
      roles={roles}
      adminDropdown={adminDropdown}
      getAdminRoleDetails={getAdminRoleDetails} />

      <UserDetailsTable 
      setUserDetails={setUserDetails}
      userDetails={userDetails}
      isLoading={isLoading}
      editUserDetails={editUserDetails} />
    </>
  )
}

export default UserDetails