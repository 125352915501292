import axios from 'axios';
import React, { useEffect } from 'react'
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from 'react-select'
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { VARIABLES } from '../../Constant';
import secureLocalStorage from "react-secure-storage";

const AdminDetailsEdit = (props) => {
  // Secure local storage data
  const privileges = secureLocalStorage.getItem('privileges')
  const userId = secureLocalStorage.getItem('userID')
  const loggedId = secureLocalStorage.getItem('loggedId')

  // react hook form state
  const { register, handleSubmit, watch, formState: { errors }, control, reset } = useForm();
  const { isDirty, isValid } = useFormState({
    control
  });

  // Onsubmit handler to submit form to "Edit form"
  const onSubmit = (data) => {
    console.log(data)
    console.log(data.role)
    var userDetails
    {
      privileges.includes("admin") ? userDetails = JSON.stringify({
        "first_name": data.first_name,
        "last_name": data.last_name,
        "username": data.username,
        "email": data.email,
        "superior_id": userId,
        "mobile": data.mobile,
        // "role" : props.editedUserData.role.length !== 0 ? [props.editedUserData.role[0].id] : [data.role.value],
        "role": data.role ? [data.role.value] : [props.editedUserData.role[0].id],
        "updated_by_id": loggedId
      }) :
        userDetails = JSON.stringify({
          "first_name": data.first_name,
          "last_name": data.last_name,
          "username": data.username,
          "email": data.email,
          "superior_id": data.superior_id === "" ? 0 : data.superior_id.value,
          "mobile": data.mobile,
          // "role" : props.editedUserData.role.length !== 0 ? [props.editedUserData.role[0].id] : [data.role.value],
          "role": data.role ? [data.role.value] : [props.editedUserData.role[0].id],
          "updated_by_id": loggedId
        })
    }
    console.log(userDetails)

    const url = VARIABLES.url + `/api/userdetails/${props.editedUserData.id}`
    console.log(url)

    var config = {
      method: 'patch',
      url: url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: userDetails
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.status == 210) {
          swal({
            icon: 'error',
            title: `${response?.data?.status}`,
            showConfirmButton: false,
          })
        } else if (response.status == 211) {
          swal({
            icon: 'error',
            title: `${response?.data?.status}`,
            showConfirmButton: false,
          })
        } else {
          swal({
            icon: "success",
            title: "User Updated Successfully",
            showConfirmButton: false,
          }).then(() => {
            privileges.includes("superadmin") ? props.getUserDetails()
              : props.getAdminRoleDetails()
          })
        }
      })
      .catch((error) => {
        console.log(error);
        const resStatus = error.response.status
        if (resStatus == 209) {
          swal({
            icon: 'error',
            title: 'The entered mobile number is already in used',
            showConfirmButton: false,
          })
        } else if (resStatus == 402) {
          swal({
            icon: 'error',
            title: `${error.response.data.status}`,
            showConfirmButton: false,
          })
        } else if (resStatus == 210) {
          swal({
            icon: 'error',
            title: 'The entered Email is already in used',
            showConfirmButton: false,
          })
        } else if (resStatus == 211) {
          swal({
            icon: 'error',
            title: 'The entered Username is incorrect',
            showConfirmButton: false,
          })
        } else {
          swal({
            icon: 'error',
            title: 'Failed to update User',
            showConfirmButton: false,
          })
        }
      });
    console.log(data)
    reset();
  };

  // prepopulation of values 
  useEffect(() => {
    console.log(props.editedUserData)
    if (props.editedUserData) {
      var defaultValues = {}

      defaultValues.first_name = props.editedUserData.user.first_name
      defaultValues.last_name = props.editedUserData.user.last_name
      defaultValues.username = props.editedUserData.user.username
      defaultValues.email = props.editedUserData.user.email
      defaultValues.password = props.editedUserData.user.password

      if (props.editedUserData.mobile) {
        defaultValues.mobile = props.editedUserData.mobile.replace(/^\+91/, "")
      }
      // const getRole = props.editedUserData.role.map((item) => {
      //   return {value: item.id,
      //     label: item.name}
      // })
      //   defaultValues.role = getRole

      const getRole = {
        value: props.editedUserData.role?.[0]?.id,
        label: props.editedUserData.role?.[0]?.name
      }
      defaultValues.role = getRole

      const getSuperId = props.adminDropdown.find((item) => {
        return item.value === props.editedUserData.superior_id
      })
      console.log(getSuperId)
      defaultValues.superior_id = getSuperId
      reset(defaultValues)
    }
  }, [props.editedUserData])

  return (
    <>
      {/* <!-- Modal --> */}
      <div className="modal fade" id="editAdmin" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Edit User</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='mb-3'>
                  <label className="form-label">First Name <span className="required_field">*</span></label>
                  <input className="form-control" type="text" placeholder="Enter the First Name" {...register("first_name", { required: true })} />
                  {errors.first_name && <span className='required_field'>First name is required</span>}
                </div>
                <div className='mb-3'>
                  <label className="form-label">Last Name <span className="required_field">*</span></label>
                  <input className="form-control" type="text" placeholder="Enter the Last Name" {...register("last_name", { required: true })} />
                  {errors.last_name && <span className='required_field'>Last name is required</span>}
                </div>
                {privileges.includes("superadmin") && <div className="mb-3">
                  <p className='required_field'>Note : While creating plant owner , assigned the admin to Plant owner</p>
                  <label className="form-label">Admin</label>
                  <Controller
                    render={({ field }) => <Select {...field} options={props.adminDropdown} />}
                    defaultValue=""
                    name="superior_id"
                    control={control} />
                </div>}
                <div className='mb-3'>
                  <label className="form-label">Username <span className="required_field">*</span></label>
                  <input className="form-control" type="text" placeholder="Enter the Username" {...register("username", { required: true })} />
                  {errors.username && <span className='required_field'>Username is required</span>}
                </div>
                {/* <div className='mb-3'>
                  <label className="form-label">Password <span className="required_field">*</span></label>
                  <input className="form-control" type="password" placeholder="Enter the Password" {...register("password", { required: true })} />
                </div> */}
                <div className='mb-3'>
                  <label className="form-label">Email <span className="required_field">*</span></label>
                  <input className="form-control" type="email" placeholder="Enter the Email Address" {...register("email", { required: true })} />
                  {errors.email && <span className='required_field'>Email is required</span>}
                </div>
                <div className='mb-3'>
                  <label className="form-label"> Mobile Number <span className="required_field">*</span></label>
                  <input
                    type="number"
                    className="form-control"
                    id="number-forms-mobile_number"
                    placeholder="Enter the Mobile Number"
                    {...register("mobile", {
                      required: true,
                      maxLength: 10,
                      minLength: 10,
                      validate: {
                        startsWithValidDigit: (value) =>
                          ["9", "8", "7", "6"].includes(value.toString().charAt(0)) || "First digit should be 9, 8, 7, or 6",
                      },
                    })}
                  />
                  {errors.mobile &&
                      errors.mobile.type === "required" && (
                        <span className="fs-8 text-danger">
                          Mobile number is required
                        </span>
                      )}
                  {errors.mobile &&
                    errors.mobile.type === "minLength" && (
                      <span className="fs-8 text-danger">
                        Mobile number should be at least 10 digits
                      </span>
                    )}
                  {errors.mobile &&
                    errors.mobile.type === "maxLength" && (
                      <span className="fs-8 text-danger">
                        Mobile number should not exceed 10 digits
                      </span>
                    )}
                  {errors.mobile && errors.mobile.type === "startsWithValidDigit" && (
                    <span className="fs-8 text-danger">
                      {errors.mobile.message}
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">Role <span className="required_field">*</span></label>
                  <Controller
                    render={({ field }) => <Select {...field} options={props.roles} />}
                    defaultValue=""
                    rules={{ required: true }}
                    name="role"
                    control={control} />
                  {errors.role && <span className='required_field'>Role is required</span>}
                </div>
                <div className="modal-footer">
                  {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                  {isValid === true ? <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">Submit</button> : <button type="submit" className="btn btn-primary">Submit</button>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminDetailsEdit