import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import Swal from 'sweetalert2';
import { VARIABLES } from '../../Constant';
import DeleteModal from '../Modals/DeleteModal';

const AdminDetailsTable = (props) => {
  const privileges = secureLocalStorage.getItem('privileges')
  const [deleteResponse,setDeleteResponse]=useState(null)
  const [userId,setUserId]=useState(null)

  // Delete UserDetails for particular user
  const deleteUser = () => {
    var config = {
      method: 'delete',
      url: VARIABLES.url + `/api/delete?id=${userId}&type=user`,
      headers: {}
    };
    axios(config).then((response) => {
      Swal.fire({
        icon: 'success',
        text: 'User Deleted Successfully!'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          console.log("yes")
          document.getElementById('closeModal').click();
        }
      })
      console.log(JSON.stringify(response.data));
      props.setUserDetails(props.userDetails.filter((user) => {
        return user.id !== userId
      }));
    })
      .catch((error) => {
        Swal.fire({
          text: 'Failed to Delete User',
          icon: 'warning'
        })
        console.log(error);
      });
  };

  // Pass the ID for particular user and after confirmation the user gets deleted.
  const deleteUserAlert = async(id) => {
    console.log(id)
    try {
      const res = await axios.get(VARIABLES.url+"/api/delete-confirmation?id="+id+"&type=user");
      document.getElementById('modalDelete').click();
      console.log(res.data)
      setDeleteResponse(res.data)
      setUserId(id)
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className='row'>
        <div className='col-lg-12 col-md-12 col-sm-12'>
          <div className='card'>
            {props.isLoading ? <div className='card-inner'>Loading...</div> :
              <div className='card-inner'>
                <div className='table-responsive'>
                  <table className="datatable-init nk-tb-list nk-tb-orders">
                    <thead>
                      <tr class="nk-tb-item nk-tb-head">
                        <td className="nk-tb-col nk-tb-orders-type"><span>Sr.No</span></td>
                        <td className="nk-tb-col"><span>Username</span></td>
                        <td className="nk-tb-col"><span>Email</span></td>
                        <td className="nk-tb-col"><span>Role</span></td>
                        <td className="nk-tb-col"><span>Edit</span></td>
                        <td className="nk-tb-col"><span>Delete</span></td>
                      </tr>
                      {/* <!-- .nk-tb-item --> */}
                    </thead>
                    {props.userDetails.length === 0 &&
                      <tbody>
                        <tr>
                          <td></td>
                          <td>No data found</td>
                          <td></td>
                        </tr>
                      </tbody>
                    }
                    {
                      props.userDetails.length > 0 &&
                      <tbody>
                        {props.userDetails.map((user, index) => {
                          return <tr>
                            <th className="nk-tb-col nk-tb-orders-type">
                              <span className="tb-lead">{index + 1}</span>
                            </th>
                            <td className="nk-tb-col">
                              <span className="tb-lead">{user.user.username}</span>
                            </td>
                            <td className="nk-tb-col">
                              <span className="tb-sub">{user.user.email}</span>
                            </td>
                            <td className="nk-tb-col">
                              <span className="tb-sub text-primary">{user.role.map((it) => it.name)}</span>
                            </td>
                            {privileges.includes("edit_user") && <td className="nk-tb-col"> <button className='ni ni-edit btn btn-outline-primary' data-bs-toggle="modal" data-bs-target="#editAdmin" onClick={() => props.editUserDetails(user.id)}></button> </td>}
                            {/* {privileges.includes("delete_user") && <td className="nk-tb-col"> <button className='ni ni-trash btn btn-outline-primary' onClick={() => deleteUserAlert(user.id)}></button> </td>} */}

                            {user.role.some(it => it.name == "super admin") ? 
                            <div className="nk-tb-col">Deletion of the Superadmin role is not allowed.</div> : privileges.includes("delete_user") &&
                            <td className="nk-tb-col">
                              <button className='ni ni-trash btn btn-outline-primary' onClick={() => deleteUserAlert(user.id)}></button>
                            </td>
                          }
                          </tr>
                        })}
                      </tbody>
                    }
                  </table>
                </div>
              </div>}
          </div>
        </div>
      </div>
      
      <DeleteModal message="Warning: Deleting this User will effects the below following entities:"
       deleteResponse={deleteResponse} 
      deleteEntity={deleteUser}
      userId={userId} />
      <button
        id="modalDelete"
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#assignedDelete"
        style={{ display: 'none' }}
      >
        Open Modal 2
      </button>
      <button
        id="closeModal"
        type="button"
        className="btn btn-primary"
        data-bs-target="#assignedDelete"
        data-bs-dismiss="modal" aria-label="Close"
        style={{ display: 'none' }}
      >
        Close Modal 2
      </button>
    </>
  )
}
export default AdminDetailsTable