import React, { useState, useEffect } from 'react';
import Login from './Components/Authentication/Login'
import Base from './Components/Base/Base'
import  secureLocalStorage  from  "react-secure-storage";
import { BrowserRouter } from 'react-router-dom';

const App = () => {
  const [isLoggedIn,setIsLoggedIn] = useState(false)
  console.log(isLoggedIn)

    // Set values in localstorage, userData and isLoggedIn after successful login
    //TODO : get privileges from backend directly
    const loginHandler = (data) => {
      console.log(data)
      secureLocalStorage.setItem('isLoggedIn','USER_LOGGED_IN');
      secureLocalStorage.setItem('userID',data.id);
      secureLocalStorage.setItem('loggedId',data.user.id);
      secureLocalStorage.setItem('username',data.user.username);
      secureLocalStorage.setItem('email',data.user.email);
      secureLocalStorage.setItem('superior_id',data.superior_id);
      secureLocalStorage.setItem('role_name',data.role[0].name);
      const privileges_list = data.role[0].privileges
      const privileges = privileges_list.map((pr) => {return pr.name})
      console.log(privileges)
      console.log(privileges_list)
      secureLocalStorage.setItem('privileges',privileges)
      // secureLocalStorage.setItem('role',data[0].role.name)
      // setUserData(data);
      setIsLoggedIn(true);
    };

  const logoutHandler = () => {
    secureLocalStorage.removeItem('isLoggedIn','USER_LOGGED_OUT')
    secureLocalStorage.removeItem('userID');
    secureLocalStorage.removeItem('loggedId');
    secureLocalStorage.removeItem('username');
    secureLocalStorage.removeItem('email');
    secureLocalStorage.removeItem('superior_id');
    secureLocalStorage.removeItem('role_name');
    secureLocalStorage.removeItem('privileges')
    secureLocalStorage.removeItem('plant_id')
    secureLocalStorage.removeItem('admin_id')
    secureLocalStorage.removeItem('pump_id')
    secureLocalStorage.clear();
    setIsLoggedIn(false);
  };

  useEffect(()=>{
    const userStatus = secureLocalStorage.getItem('isLoggedIn');
    if(userStatus==='USER_LOGGED_IN'){
      setIsLoggedIn(true);
    }else if(userStatus==='USER_LOGGED_OUT'){
      setIsLoggedIn(false);
    }
  },[])
  
  return (
    <>
    <BrowserRouter>
      {isLoggedIn && <Base logoutHandler={logoutHandler} />}
      {!isLoggedIn && <Login loginHandler={loginHandler} />}
    </BrowserRouter>
    </>
  )
}

export default App
