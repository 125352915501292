// Overview of dashboard Having 2 points 
// 1. Total Count of Admin 
// 2. Zone Switiching Logs of All Zones
import axios from 'axios'
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import secureLocalStorage from 'react-secure-storage';
import { VARIABLES } from '../../Constant';

const OverviewDashboard = (props) => {
  const userId = secureLocalStorage.getItem('userID')
  console.log(userId)
  const privileges = secureLocalStorage.getItem('privileges')
  // all admin Details are store in "admins" state
  const [admins,setAdmins] = useState([])
  console.log(admins)

  // all plant details are stored in "plantOwner" state
  const [plantOwner,setPlantOwner] = useState([])
  console.log(plantOwner)

  // All pumps details for particular user after logged with "plantowner"
  const [pumps,setPumps] = useState([])
  console.log(pumps)

  // Onchnage handle to switch from one page to anathor
  const onChangeSidebar = (section) => {
    props.handleSidebar(section)
  }

  // api call to get the admin "count" details role wise and store in state "admins"
  const apiAdminCount = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/userdetails?role=admin');
      setAdmins(res.data)
    } catch (error) {
      console.log(error);
    }
  };

  // api call to get the plant count for particular admin
  const apiPlantCount = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/entity-details?type=plant&user_id=' + userId);
      setPlantOwner(res.data)
    } catch (error) {
      console.log(error);
    }
  };


  // api call to get pump count for particular plant
  const apiPumpCount = async () => {
    try {
      const url = VARIABLES.url + '/api/entity-details?type=pump&user_id='+userId
      const res = await axios.get(url);
      setPumps(res.data)
      console.log(res.data)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    {privileges.includes("superadmin") && apiAdminCount()}
    {privileges.includes("admin") && apiPlantCount()}
    {privileges.includes("plantowner") && apiPumpCount()}
  },[])

  return (
    <>
      <div className="row gy-0">
        {privileges.includes("admin_count") && <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="card is-dark h-100">
            <div className="nk-ecwg nk-ecwg1">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title total_admin">Total Admin</h6>
                  </div>
                  <div className="card-tools">
                    <a href="javascript:void(0)" className="link card-link" onClick={() => onChangeSidebar("Admin Overview")}>View</a>
                  </div>
                </div>
                <div className="data">
                  <div className="amount"> {admins.length} </div>
                  <div className="info"><strong>count</strong> in last month</div>
                </div>
              </div>
              {/* <!-- .card-inner --> */}
            </div>
            {/* <!-- .nk-ecwg --> */}
          </div>
          {/* <!-- .card --> */}
        </div>}

        {privileges.includes("plant_count") && <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="card is-dark h-100">
            <div className="nk-ecwg nk-ecwg1">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title total_admin">Total Plant</h6>
                  </div>
                  <div className="card-tools">
                    <a href="javascript:void(0)" className="link card-link" onClick={() => onChangeSidebar("Plant Overview")}>View</a>
                  </div>
                </div>
                <div className="data">
                  <div className="amount"> {plantOwner.length} </div>
                  <div className="info"><strong>count</strong> in last month</div>
                </div>
              </div>
              {/* <!-- .card-inner --> */}
            </div>
            {/* <!-- .nk-ecwg --> */}
          </div>
          {/* <!-- .card --> */}
        </div>}

        {privileges.includes("pump_count") && <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="card is-dark h-100">
            <div className="nk-ecwg nk-ecwg1">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title total_admin">Total Pump</h6>
                  </div>
                </div>
                <div className="data">
                  <div className="amount"> {pumps.length} </div>
                  <div className="info"><strong>count</strong> in last month</div>
                </div>
              </div>
              {/* <!-- .card-inner --> */}
            </div>
            {/* <!-- .nk-ecwg --> */}
          </div>
          {/* <!-- .card --> */}
        </div>}
        {/* <!-- .col --> */}

        <div className="col-lg-4 col-md-4 col-sm-12 overview_admin">
          <div className="card h-100">
            <div className="nk-ecwg nk-ecwg2">
              <div className="card-inner">
                <div className="card-title-group mt-n1">
                  <div className="card-title">
                    <h6 className="title">Pump Switching Log</h6>
                  </div>
                  {privileges.includes("pump_logs") && <div className="card-tools">
                    <a href="javascript:void(0)" className="link card-link" onClick={() => onChangeSidebar("pump feedback")}>View</a>
                  </div>}
                </div>
                <div className="data">
                  <div className="amount">2</div>
                  <div className="info"><strong>count</strong></div>
                </div>
              </div>
              {/* <!-- .card-inner --> */}
            </div>
            {/* <!-- .nk-ecwg --> */}
          </div>
          {/* <!-- .card --> */}
        </div>
        {/* <!-- .col --> */}
        <div className="col-lg-4 col-md-4 col-sm-12 overview_admin">
          <div className="card h-100">
            <div className="nk-ecwg nk-ecwg2">
              <div className="card-inner">
                <div className="card-title-group mt-n1">
                  <div className="card-title">
                    <h6 className="title">Zone Switching Log</h6>
                  </div>
                  {privileges.includes("zone_logs") && <div className="card-tools">
                    <a href="javascript:void(0)" className="link card-link" onClick={() => onChangeSidebar("zone feedback")}>View</a>
                  </div>}
                </div>
                <div className="data">
                  <div className="amount">6</div>
                  <div className="info"><strong>count</strong></div>
                </div>
              </div>
              {/* <!-- .card-inner --> */}
            </div>
            {/* <!-- .nk-ecwg --> */}
          </div>
          {/* <!-- .card --> */}
        </div>
        {/* <!-- .col --> */}
      </div>
    </>
  )
}

export default OverviewDashboard