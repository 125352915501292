import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import secureLocalStorage from "react-secure-storage";
import Swal from 'sweetalert2';
import { VARIABLES } from '../../Constant';
import DeleteModal from '../Modals/DeleteModal';

const AllPlantOwnerCards = (props) => {
  const privileges = secureLocalStorage.getItem('privileges')
  const [deleteResponse,setDeleteResponse]=useState(null)
  const [userId,setUserId]=useState(null)
  // Onchnage handle to swtich from one page to anathor
  const onChangeSidebar = (section, plant_id) => {
    secureLocalStorage.setItem('plant_id', plant_id);
    console.log(plant_id)
    props.handleSidebar(section)
  }

  const deletePlant = () => {
    var config = {
      method: 'delete',
      url: VARIABLES.url + `/api/delete?id=${userId}&type=entity`,
      headers: {}
    };
    axios(config).then((response) => {
      Swal.fire({
        text : 'Plant Deleted Successfully!',
        icon : 'success'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          console.log("yes")
          document.getElementById('closeModal').click();
          props.setPlants(props.plants.filter((user) => {
            return user.id !== userId
          }));
        }
      })
      console.log(JSON.stringify(response.data));
      // window.location.reload();
    })
      .catch((error) => {
        Swal.fire({
          text : 'Failed to delete Plant',
          icon : 'warning'
        })
        console.log(error);
      });
  };

  const deletePlantAlert = async(id) => {
    try {
      const res = await axios.get(VARIABLES.url+"/api/delete-confirmation?id="+id+"&type=entity");
      document.getElementById('modalDelete').click();
      console.log(res.data)
      setDeleteResponse(res.data)
      setUserId(id)
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className='row' style={{ marginTop: "10px" }}>
        {props.isLoading ? <div className='card-inner'>Loading...</div> :
          <>
            {props.plants.length === 0 &&
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='card'>
                    <div className='card-inner'>
                      Plants are not created
                    </div>
                  </div>
                </div>
              </div>
            }
            {props.plants.map((plant) => {
              return <div className="col-sm-6 col-lg-4 col-xxl-3">
                <div className="card">
                  <div className="card-inner">
                    <div className="team">
                    {privileges.includes("plant_ellipsis") && <div className="team-options">
                        <div className="dropdown">
                          <a href="#" className="dropdown-toggle btn btn-sm btn-icon btn-trigger" data-bs-toggle="dropdown" aria-expanded="false"><em className="icon ni ni-more-h"></em></a>
                          <div className="dropdown-menu dropdown-menu-end">
                            <ul className="link-list-opt no-bdr">
                            {privileges.includes("edit_plant") && <li><a href='javascript:void(0)' data-bs-toggle="modal" data-bs-target="#editPlant" onClick={() => props.editPlant(plant.id)}><em className="icon ni ni-edit"></em><span>Edit Plant</span></a></li>}
                            {privileges.includes("delete_plant") && <li><a href='javascript:void(0)' onClick={() => deletePlantAlert(plant.id)}><em className="icon ni ni-delete"></em><span>Delete Plant</span></a></li>}
                            </ul>
                          </div>
                        </div>
                      </div>}
                      <div className="user-card user-card-s2">
                        {/* <div className="user-avatar md bg-primary">
                          <span>AB</span>
                        </div> */}
                        <div className="user-info">
                          <h6> {plant.name} </h6>
                          {/* <span className="sub-text">plantowner@gmail.com</span> */}
                        </div>
                      </div>
                      {privileges.includes("view_pump_details") && <div className="team-view mt-3">
                        <a href="javascript:void(0)" className="btn btn-round btn-outline-light w-150px" onClick={() => onChangeSidebar("pump_details", plant.id)}><span>Pump</span></a>
                      </div>}
                    </div>
                    {/* <!-- .team --> */}
                  </div>
                  {/* <!-- .card-inner --> */}
                </div>
                {/* <!-- .card --> */}
              </div>
            })}
          </>
        }
        {/* <!-- .col --> */}
      </div>

      <DeleteModal message="Warning: Deleting this Plant will effects the below following entities:"
      deleteResponse={deleteResponse} 
      deleteEntity={deletePlant}
      userId={userId} />
      <button
        id="modalDelete"
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#assignedDelete"
        style={{ display: 'none' }}
      >
        Open Modal 2
      </button>
      <button
        id="closeModal"
        type="button"
        className="btn btn-primary"
        data-bs-target="#assignedDelete"
        data-bs-dismiss="modal" aria-label="Close"
        style={{ display: 'none' }}
      >
        Close Modal 2
      </button>
    </>
  )
}

export default AllPlantOwnerCards