import axios from 'axios';
import React from 'react'
import { Controller, useForm,useFormState } from "react-hook-form";
import Select from 'react-select'
import Swal from 'sweetalert2';
import { VARIABLES } from '../../Constant';
import  secureLocalStorage  from  "react-secure-storage";
import swal from 'sweetalert';

const ZoneManagementCreate = (props) => {
  // get the localstorage value of "loggedId"
  const LoggedId = secureLocalStorage.getItem("loggedId")

  // React hook form 
  const { register, handleSubmit, watch, formState: { errors }, control ,reset} = useForm();
  const { isDirty, isValid } = useFormState({
    control
  });

  // onsubmit handler to create a "zone"
  const onSubmit = (data) => {
    var zoneDetails = JSON.stringify({
      "name": data.name,
      "user_id": data.user_id.value,
      "location": data.location,
      "parent_id": data.parent_id.value,
      "node_id": props.selectedNode.value,
      "project_id": VARIABLES.project_id,
      "type": "zone",
      "pin_number" : data.pin_number.value,
      "mqtt_topic": `${VARIABLES.subdomain}/${VARIABLES.project_id}/${props.selectedNode.value}/P_${data.pin_number.value}/values`,
      "input_output": "O",
      "created_by_id" : LoggedId
    });
    
    var config = {
      method: 'post',
      url: VARIABLES.url+"/api/entity-details",
      headers: {
        'Content-Type': 'application/json'
      },
      data: zoneDetails
    };
    
    axios(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      swal({
        icon : "success",
        title: "Zone Created Successfully",
        showConfirmButton: false,
      }).then(() => props.apiZoneDetails(), props.getNodePin(), props.apiNodeData())
    })
    .catch((error) => {
      console.log(error);
      console.log(error.response.data)
      if(error.response.status === 400) {
        Swal.fire(
          "The Pin number is already taken for this node",
          error.response.data.message,
          'error'
        )
      } else {
        swal({
          icon: 'error',
          title: 'Failed to create Zone',
          showConfirmButton: false,
        })
      }
    });
    console.log(data)
    reset();
    props.setSelectedNode("");
  };
  
  return (
    <>
      {/* <!-- Modal --> */}
      <div className="modal fade" id="zoneAdd" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Create Zone</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className='mb-3'>
                  <label className="form-label">Zone Name <span className="required_field">*</span></label>
                  <input className="form-control" type="text" placeholder="Enter the Zone Name" {...register("name", { required: true })} />
                  {errors.name && <span className='required_field'>Zone name is required</span>}
                </div>

                <div className="mb-3">
                  <label className="form-label">User <span className="required_field">*</span></label>
                  <Controller
                    render={({ field }) => <Select {...field} options={props.userdetails} />}
                    defaultValue=""
                    rules={{ required: true }}
                    name="user_id"
                    control={control} />
                    {errors.user_id && <span className='required_field'>User Id is required</span>}
                </div>

                <div className='mb-3'>
                  <label className="form-label">Location</label>
                  <input className="form-control" type="text" placeholder="Enter the Location" {...register("location")} />
                </div>

                <div className='mb-3'>
                  <label className="form-label">Pump <span className="required_field">*</span></label>
                  <Controller
                    render={({ field }) => <Select {...field} options={props.zoneDetails} />}
                    defaultValue=""
                    rules={{ required: true }}
                    name="parent_id"
                    control={control} />
                    {errors.parent_id && <span className='required_field'>Pump Id is required</span>}
                </div>

                <div className="mb-3">
                  <label className="form-label">Node Id <span className="required_field">*</span></label>
                  <Controller
                    render={({ field }) => <Select 
                    {...field} 
                    value={props.selectedNode}
                    onChange={(data) => {
                      props.setSelectedNode(data)
                      field.onChange(data)
                    }}
                    noOptionsMessage={() => 'No Nodes Avaliable.'}
                    options={props.nodeData} />}
                    defaultValue=""
                    rules={{ required: true }}
                    name="node_id"
                    control={control} />
                    {errors.node_id && <span className='required_field'>Node Id is required</span>}
                </div>

                <div className="mb-3">
                  <label className="form-label">Pin Number <span className="required_field">*</span></label>
                  <Controller
                    render={({ field }) => <Select {...field} 
                    options={props.options}
                    noOptionsMessage={() => 'No Pins Avaliable. Please select Node first'} />}
                    defaultValue=""
                    rules={{ required: true }}
                    name="pin_number"
                    control={control} />
                    {errors.pin_number && <span className='required_field'>Pin Number is required</span>}
                </div>
                <div className="modal-footer">
                  {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                  {isValid === true ?  <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">Submit</button> : <button type="submit" className="btn btn-primary">Submit</button>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ZoneManagementCreate