import React, { useEffect, useState, useContext } from 'react'
import ZoneManagementCreate from '../ZoneManagement/ZoneManagementCreate'
import ZoneManagementEdit from '../ZoneManagement/ZoneManagementEdit'
import { VARIABLES } from '../../Constant';
import axios from 'axios'
import secureLocalStorage from "react-secure-storage";
import Swal from 'sweetalert2';
import ZoneRender from './ZoneRender';
import { PumpDetailsContext } from '../../Context/PumpDetailsContext';

const ZoneDetails = (props) => {
  const privileges = secureLocalStorage.getItem('privileges')
  const userId = secureLocalStorage.getItem('userID')
  const plantId = secureLocalStorage.getItem('plant_id')

  const {
    setPumpDetails, pumpDetails } = useContext(PumpDetailsContext);

  // edited details for pumps gets stored here
  const [editedZone, setEditedZone] = useState(null)

  // isLoading state for data loading
  const [isLoading, setIsLoading] = useState(false)

  // stored plantowner details in "userdetails" to pass as dropdown in zone create/edit form
  const [userdetails, setUserDetails] = useState([]);

  // After selecting the node the node value stored in "selectedNode" state
  const [selectedNode, setSelectedNode] = useState([])
  console.log(selectedNode)

  // "zoneDetails" stored pump id and label as {value , label} to pass in dropdown 
  const [zoneDetails, setZoneDetails] = useState([])

  // Stored zone data in "zones" state 
  const [zones, setZones] = useState([]);
  console.log(zones)

  // Stored node id in "nodeData"
  const [nodeData, setNodeData] = useState([])

  // Raw data of (node and pins avaliable and unavaliable)
  const [nodePinData, setNodePinData] = useState([])
  console.log(nodePinData)

  // options to be send in React Select dropdown for pins
  const [options, setOptions] = useState([])

  // API call to get plantowner details stored in "userdetails" (dropdown)
  const apiUserDetails = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/userdetails?role=plant owner');
      setUserDetails(
        res.data.map((userData) => ({
          value: userData.id,
          label: userData.user.username
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  // Provision for "superadmin" , "admin", "plant owner" hererchy to show particular zone of selected pump
  // After clicking on pump the "id" of pump gets passed below and specific zone for that pump gets populated
  const apiZoneDetails = async () => {
    try {
      setIsLoading(true)
      const url = VARIABLES.url + '/api/entity-details?type=zone&parent_id=' + pumpDetails
      console.log(url)
      const res = await axios.get(url);
      setZones(res.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error);
    }
  };

  // provision for "superadmin" and "admin" hererchy dropdown
  const apiPumpDropdownDetails = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/entity-details?type=pump&parent_id=' + plantId);
      setZoneDetails(
        res.data.map((userData) => ({
          value: userData.id,
          label: userData.name
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  // provision for "plantowner" to populate pump dropdown in zone form  
  const apiPumptoZoneDetails = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/entity-details?type=pump&user_id=' + userId);
      setZoneDetails(
        res.data.map((userData) => ({
          value: userData.id,
          label: userData.name
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  // Api call to get Node Id 
  const apiNodeData = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/node-details');
      setNodePinData(res.data)
      setNodeData(res.data.map((node) => ({
        value: node.name,
        label: node.name
      })))
      console.log(res.data)
    } catch (error) {
      console.log(error);
    }
  };

  // Function to get specific id details and stored in state "editedPump"
  const editZone = (id) => {
    const getEditedZone = zones.find((zone) => {
      return zone.id === id
    })
    console.log(getEditedZone);
    setEditedZone(getEditedZone);
  }

  // to get specific pin of selected node
  const getNodePin = () => {
    if (selectedNode.length === 0) {
      console.log("hi")
    } else {
      const selectedPins = nodePinData.find((pin) => pin.name === selectedNode.label)
      console.log(selectedPins)
      const availableOptions = selectedPins.output_pin_available.map((value) => ({
        value: value,
        label: value,
        isDisabled: false, // output_pin_available should be enabled
      }));

      const unavailableOptions = selectedPins.output_pin_unavailable.map((value) => ({
        value: value,
        label: value,
        isDisabled: true, // output_pin_unavailable should be disabled
      }));
      setOptions(availableOptions.concat(unavailableOptions))
    }
  }

  useEffect(() => {
    apiUserDetails();
    apiNodeData();
    getNodePin();
    { pumpDetails && apiZoneDetails(); }
    { privileges.includes("superadmin_admin") && pumpDetails && apiPumpDropdownDetails(); }
    { privileges.includes("plantowner") && apiPumptoZoneDetails(); }
  }, [selectedNode, pumpDetails])

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
          </div>
          {/* <!-- .nk-block-head-content --> */}
          {privileges.includes("add_zone") && <div class="nk-block-head-content">
            <div className="col-auto d-flex w-sm-100">
              <button type="button" className="createzone btn btn-primary" data-bs-toggle="modal" data-bs-target="#zoneAdd"><em class="icon ni ni-plus"></em><span>Create Zone</span></button>
            </div>
          </div>}
        </div>
      </div>

      {/* GEt View of all ZOne */}
      <div className='row mx-0'>
        {isLoading ? <div className='card-inner'>Loading...</div> :
          <>
          {
            Array.isArray(zones) ? 
            <>
            {zones.length === 0 &&
              <div className='row'>
                <div className='col-sm-12' style={{ marginTop: "20px" }}>
                  <div className='card'>
                    <div className='card-inner'>
                      Zones are not created
                    </div>
                  </div>
                </div>
              </div>
            }
            {
              zones.map((zone) => {
                return <ZoneRender zone={zone}
                  editZone={editZone}
                  setZones={setZones}
                  zones={zones}
                  handleSidebar={props.handleSidebar}
                  setSchedularZone={props.setSchedularZone}
                  isLoading={isLoading}
                  apiNodeData={apiNodeData}
                  getNodePin={getNodePin}
                />
              })
            }
            </> : <div className='row'>
                <div className='col-sm-12' style={{ marginTop: "20px" }}>
                  <div className='card'>
                    <div className='card-inner'>
                      Tanks are not created
                    </div>
                  </div>
                </div>
              </div>
          }
          </>
        }
      </div>

      <ZoneManagementCreate userdetails={userdetails}
        zoneDetails={zoneDetails}
        apiZoneDetails={apiZoneDetails}
        nodeData={nodeData}
        selectedNode={selectedNode}
        setSelectedNode={setSelectedNode}
        options={options}
        apiNodeData={apiNodeData}
        getNodePin={getNodePin} />

      <ZoneManagementEdit userdetails={userdetails}
        zoneDetails={zoneDetails}
        editedZone={editedZone}
        apiZoneDetails={apiZoneDetails}
        nodeData={nodeData}
        selectedNode={selectedNode}
        setSelectedNode={setSelectedNode}
        options={options} />
    </>
  )
}

export default ZoneDetails